import ReactGA from 'react-ga4';

const useGAEventsTracker = (category = 'clicks') => {
    return (
        action = 'action',
        label = '',
        value = undefined,
        nonInteraction = undefined,
        transport = undefined
    ) => {
        // Required
        const eventProps = {
            category,
            action,
        };
        // Optional
        if (label) {
            // String
            eventProps.label = label;
        }
        if (value) {
            // Int
            eventProps.value = value;
        }
        if (nonInteraction) {
            // Boolean
            eventProps.nonInteraction = nonInteraction;
        }
        if (transport) {
            // String
            eventProps.transport = transport;
        }
        ReactGA.event(eventProps);
        //console.log('Information sent----->>>>',eventProps);
    };
};

// const useGAEventsTracker = (category = '') => {
//     if(category) {
//         return (action = 'action', label = '', value = undefined, nonInteraction = undefined, transport = undefined) => {
//             const eventProps = {
//                 category, action
//             };
//             if(label){
//                 eventProps.label = label;
//             }
//             ReactGA.event(eventProps);
//             // console.log('Information sent----->>>>',category, action, label);
//         };
//     }
// };

export default useGAEventsTracker;
