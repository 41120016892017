import { transparentize } from "polished";

import { TIMEZONES } from "../../data/constants";

import wrap from "./core/wrap";

import Select, {
	OptionComponentProps,
	ShowcaseComponentProps
} from "./select";

import {
	Option,
	WrappedInputProps
} from "../../types/forms";
import styled from "styled-components";

interface TzSelectProps {
	alt?: boolean;
	compact?: boolean;
}

interface TzSelectHeadProps {
	isAlt?: boolean;
	readOnly?: boolean;
	inputProps: WrappedInputProps<string>;
}

const TIME_ZONE_OPTIONS: Option<string>[] = TIMEZONES.map(tz => ({
	value: tz.zone,
	label: tz.shortName,
	data: {
		fullName: tz.name
	}
}));

const TzSelectOptionWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 8px 12px;
	white-space: nowrap;
`;

const TzNameText = styled.span`
	flex-grow: 1;
`;

const TzLabelText = styled.span`
	opacity: 0.9;
	color: ${p => p.theme.lightColor};
`;

const TzSelectHeadWrapper = styled.button<TzSelectHeadProps>`
	${p => p.inputProps.baseStyle};
	padding: ${p => p.readOnly ? "3px 10px" : "3px 8px"};
	border-radius: 20px;
	color: ${p => p.theme.popContrastColor};
	background: ${p =>
		p.isAlt ?
			p.theme.popBackgroundAlt :
			p.theme.popBackground
	};
	margin: 2px 0;
	cursor: pointer;
	
	&:focus {
		box-shadow: 0 0 0 3px ${p =>
			p.isAlt ?
				transparentize(0.6, p.theme.popBackgroundAlt) :
				transparentize(0.6, p.theme.popBackground)
		};
	}
`;

const TzSelectHeadContent = styled.div<TzSelectHeadProps>`
	position: relative;
	font-size: 90%;

	&:after {
		content: "";
		display: ${p => p.readOnly ? "none" : "inline-block"};
		width: 0.4em;
		height: 0.4em;
		vertical-align: 0.25em;
		transform: rotate(45deg);
		border-bottom: 0.15em solid;
		border-right: 0.15em solid;
		margin: 0 0.15em 0 0.3em;
	}
`;

const TzSelectHead = (props: ShowcaseComponentProps<string>) => {
	const label = props.selectable.selection[0].label,
		p = {
			isAlt: (props.inputProps as TzSelectProps).alt,
			readOnly: props.inputProps.readOnly,
			inputProps: props.inputProps
		} as TzSelectHeadProps;

	return (
		<TzSelectHeadWrapper
			{...p}
			onClick={() => props.dropProps.expand()}
		>
			<TzSelectHeadContent {...p}>{label}</TzSelectHeadContent>
		</TzSelectHeadWrapper>
	);
};

const TzSelectOption = (props: OptionComponentProps<string>) => {
	return (
		<TzSelectOptionWrapper>
			<TzNameText>{props.option.data.fullName}</TzNameText>
			<TzLabelText>{props.option.label}</TzLabelText>
		</TzSelectOptionWrapper>
	);
};

const TzSelectComponent = (props: WrappedInputProps<string, TzSelectProps>) => {
	if (props.compact) {
		return (
			<Select
				{...props}
				options={TIME_ZONE_OPTIONS}
				option={TzSelectOption}
				bareShowcase={TzSelectHead}
				hash={opt => opt.value}
				detached={props.compact}
			/>
		);
	}

	return (
		<Select
			{...props}
			options={TIME_ZONE_OPTIONS}
			option={TzSelectOption}
			hash={opt => opt.value}
			detached={props.compact}
		/>
	);
};

const TzSelect = wrap({
	observe: [],
	component: TzSelectComponent
});

export default TzSelect;
