import styled from "styled-components";

const CardWrapper = styled.article`
	padding: ${p => p.theme.padding};
	background: ${p => p.theme.cardBackground};
	border: ${p => p.theme.boundaryBorder};
	border-radius: ${p => p.theme.borderRadius};
	box-shadow: ${p => p.theme.boxShadow};
`;

const Card = (props: any) => (
	<CardWrapper className={props.className}>
		{props.children}
	</CardWrapper>
);

export default Card;
