import styled from "styled-components";

import wrap from "./core/wrap";

import { WrappedInputProps } from "../../types/forms";

const TextAreaWrapper = styled.textarea<WrappedInputProps<string>>`
	${p => p.baseStyle};
	min-height: 50px;
	resize: vertical;
`;

const TextArea = wrap({
	observe: ["change"],
	component: (props: WrappedInputProps<string>) => <TextAreaWrapper {...props} />
});

export default TextArea;
