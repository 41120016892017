import {
	Form,
	Formik,
	Field,
	useFormikContext
} from "formik";

import {
	Option,
	FormEvent
} from "../types/forms";

import {
	useState,
	useEffect,
    SetStateAction
} from "react";

import {useParams} from "react-router-dom";
import styled from "styled-components";

import {
	DAYS_SHORT,
	MONTHS_SHORT,
	VENUE_OPTIONS,
	TOPIC_OPTIONS,
	ACTIVE_DAYS,
	ACTIVE_WEEK_DAYS
} from "../data/constants";

import {
	TimeEvent,
	EntryTimeEvent
} from "../util/time-blocks";

import {
	getLocalizedTime,
	getTimeZoneShortName
} from "../util/time";

import {request} from "../util/async";

import {
	Text,
	Select,
	TzSelect,
	TextArea, Check
} from "../components/inputs";

import CalendarView, {
	CellArgs,
	SelectEvent,
	UpdateEvent,
	CalendarViewProps
} from "../components/calendar-view";

import Tip from "../components/tip";
import Time from "../components/time";
import Icon from "../components/icon";
import Label from "../components/label";
import Button from "../components/button";
import Centered from "../components/centered";
import LoadingIcon from "../components/loading-icon";

import {
	useAppDispatch,
	useAppSelector
} from "../state/hooks";

import {
	setTimeZone,
	setReferenceTimeZone, setEvents, touchEvents
} from "../state/features";

import {
	BookingConfig,
	BookingRequestPayload
} from "../../../types/book";

import useGAEventsTracker from "../hooks/useGAEventsTracker";

interface SidebarProps {
	event: TimeEvent | null;
	expanded?: boolean;
}

interface ConfirmationProps {
	expanded: boolean;
}

interface TzNoticeProps {
	event: TimeEvent;
}

interface ConditionalPhoneFieldProps {
	config: BookingConfig;
}

enum LoadingStatuses {
	IDLE,
	LOADING,
	ERROR,
	LOADED
}

const Wrapper = styled.section`
	position: relative;
	display: flex;
	flex-grow: 1;
	overflow: hidden;
	max-height: calc(100vh - ${p => p.theme.headerHeight});

	@media ${p => p.theme.mobileMedia} {
		height: calc(100vh - ${p => p.theme.headerHeight});
	}
`;

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow: auto;
`;

const MenuBar = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-shrink: 0;
	padding: ${p => p.theme.padding};
	background: ${p => p.theme.cardBackground};
	border-bottom: ${p => p.theme.boundaryBorder};
`;

const LoadingMessage = styled.h2`
	margin: 0;
	font-size: 100%;
`;

const MenuLeft = styled.div`
	display: flex;
	flex-grow: 1;
	gap: 15px;
`;

const MenuRight = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;
`;

const TZControls = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const Content = styled.div`
	display: flex;
	flex-grow: 1;
	overflow: hidden;
`;

const SidebarWrapper = styled.div`
	flex-shrink: 0;
`;

const Sidebar = styled.aside<SidebarProps>`
	position: ${p =>
		p.expanded && !matchMedia(p.theme.mobileMedia).matches ?
			"relative" :
			"absolute"
	};
	display: flex;
	flex-direction: column;
	top: 0;
	right: 0;
	width: 320px;
	height: 100%;
	padding: ${p => p.theme.padding};
	background: ${p => p.theme.cardBackground};
	border-left: ${p => p.theme.boundaryBorder};
	transition: transform 300ms;
	transform: ${p => p.event ? "none" : "translateX(100%)"};
	z-index: 100;

	@media ${p => p.theme.mobileMedia} {
		width: 100%
	}
`;

const SidebarHeader = styled.div`
	display: flex;
	flex-shrink: 0;
	justify-content: space-between;
	align-items: center;
	padding: 0 0 10px 5px;
	margin-bottom: 10px;
	border-bottom: ${p => p.theme.boundaryBorder};
`;

const SidebarTitle = styled.h2`
	font-size: 100%;
	margin: 0 15px 0 0;
`;

const CloseButton = styled.button`
	width: 33px;
	height: 33px;
	background: transparent;
	border: none;
	outline: none;
	padding: 10px;
	border-radius: ${p => p.theme.borderRadius};
	cursor: pointer;

	&:focus {
		background: ${p => p.theme.background};
	}

	svg {
		width: 100%;
	}
`;

const SidebarContent = styled.article`
	position: relative;
	flex-grow: 1;
	overflow: auto;
`;

export const FormCell = styled.div`
	display: flex;
	flex-direction: column;
	margin: 5px;

	label {
		margin: 		5px 0 3px;

		&.required:after {
			content: "*";
			color: ${p => p.theme.popBackground};
			font-weight: bold;
			font-size: 90%;
			vertical-align: text-top;
			margin-left: 0.1em;
		}
	}
`;

const LoadingScreen = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: ${p => p.theme.background};
	z-index: 10;
`;

const ConfirmationScreen = styled.div<ConfirmationProps>`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 30px;
	background: ${p => p.theme.background};
	transform: ${p => p.expanded ? "none" : "translateX(100%)"};;
	opacity: ${p => p.expanded ? 1 : 0};
	transition: transform 500ms, opacity 500ms;
	z-index: 100;
`;

const ConfirmationMessage = styled.div<ConfirmationProps>`
	display: flex;
	align-items: center;
	transform: ${p => p.expanded ? "none" : "scale(0.9)"};
	opacity: ${p => p.expanded ? 1 : 0};
	transition: transform 300ms 500ms, opacity 300ms 500ms;

	svg {
		height: 60px;
		vertical-align: middle;
		stroke: ${p => p.theme.popBackground};
		fill: none;
		stroke-width: 1.5;
		margin-right: 20px;
		stroke-dasharray: 8 3;
		opacity: 0.7;
	}

	h1 {
		font-size: 170%;
		margin: 0 0 0.5em 0;
	}

	p {
		max-width: 400px;
		margin: 0;
	}

	@media ${p => p.theme.mobileMedia} {
		flex-direction: column;
		text-align: center;

		svg {
			height: 80px;
			margin: 0 0 25px;
		}
	}
`;

const FormTip = styled(Tip)`
	margin: 15px 5px 0;
`;

const TzNoticeWrapper = styled.div`
	margin-top: 30px;
`;

const FormError = styled.div`
	color:red;
`;
interface TzTableRowProps {
	event: TimeEvent;
	timeZone: string;
	reference?: boolean;
}

interface TzTableCellProps {
	reference?: boolean;
}

const TzTable = styled.div`
	display: grid;
	grid-template-columns: max-content 1fr 1fr max-content;
	margin: 10px 5px 0;
	gap: 2px 0;
`;

const TzTableBaseCell = styled.div`
	padding: 4px 6px;

	&:nth-child(4n + 1) {
		border-top-left-radius: ${p => p.theme.borderRadius};
		border-bottom-left-radius: ${p => p.theme.borderRadius};
		padding-left: 10px;
		padding-right: 12px;
	}

	&:nth-child(4n) {
		border-top-right-radius: ${p => p.theme.borderRadius};
		border-bottom-right-radius: ${p => p.theme.borderRadius};
		padding-right: 10px;
	}
`;

const TzTableHeadingCell = styled(TzTableBaseCell)`
	padding-top: 0;
	padding-bottom: 0;
`;

const TzTableHeading = () => {
	return (
		<>
			<TzTableHeadingCell>Date</TzTableHeadingCell>
			<TzTableHeadingCell>Start</TzTableHeadingCell>
			<TzTableHeadingCell>End</TzTableHeadingCell>
			<TzTableHeadingCell>Zone</TzTableHeadingCell>
		</>
	);
};

const TzTableCell = styled(TzTableBaseCell)<TzTableCellProps>`
	background: ${p => p.reference ? p.theme.popBackgroundAlt : p.theme.popBackground};
	color: ${p => p.theme.popContrastColor};
	font-weight: bold;
`;

const TzTableRow = (props: TzTableRowProps) => {
	const {
		event
	} = props;

	const startTime = getLocalizedTime(event.start, props.timeZone),
		endTime = getLocalizedTime(event.end, props.timeZone);

	return (
		<>
			<TzTableCell reference={props.reference}>
				{DAYS_SHORT[startTime.dayIndex]} {startTime.day} {MONTHS_SHORT[startTime.month]} {startTime.year}
			</TzTableCell>
			<TzTableCell reference={props.reference}>
				<Time time={startTime}/>
			</TzTableCell>
			<TzTableCell reference={props.reference}>
				<Time time={endTime}/>
			</TzTableCell>
			<TzTableCell reference={props.reference}>
				{getTimeZoneShortName(props.timeZone)}
			</TzTableCell>
		</>
	);
};

const TzNotice = (props: TzNoticeProps) => {
	const timeZone = useAppSelector(state => state.client.timeZone);
	const referenceTimeZone = useAppSelector(state => state.client.referenceTimeZone);

	const sameTz = !referenceTimeZone || timeZone === referenceTimeZone,
		tip = sameTz ?
			"Make sure to review the event date before proceeding" :
			"This event occurs in a different time zone from yours. Make sure to review the event date before proceeding";
	let referenceRow = null;

	if (!sameTz) {
		referenceRow = (
			<TzTableRow
				reference
				event={props.event}
				timeZone={referenceTimeZone}
			/>
		);
	}

	return (
		<TzNoticeWrapper>
			<FormTip>{tip}</FormTip>
			<TzTable>
				<TzTableHeading/>
				<TzTableRow
					event={props.event}
					timeZone={timeZone}
				/>
				{referenceRow}
			</TzTable>
		</TzNoticeWrapper>
	);
};

const fetchBookingConfig = async (
	link: string
): Promise<BookingConfig | string> => {
	const response = await request({
		url: `book/${link}`
	});

	if (response.success)
		return response.data;

	return response.errorMessage!;
};

const resolveCalendarViewProps = (
	config: BookingConfig,
	timeZone: string,
	referenceTimeZone: string
): CalendarViewProps => {
	const {
		weekendsOn
	} = config;

	const frame = {
		extent: 28,
		timeZone,
		days: weekendsOn ? ACTIVE_DAYS : ACTIVE_WEEK_DAYS,
		referenceTimeZone
	};

	const eventConfig = {
		minDuration: 15,
		maxDuration: 120
	};

	if (config.duration) {
		eventConfig.minDuration = config.duration;
		eventConfig.maxDuration = config.duration;
	}

	const props = {
		resizable: !config.duration,
		selectable: config.selectable,
		events: config.events,
		frame,
		eventConfig,
		availability: config.availability,
		availabilityTimeZone: config.availabilityTimeZone,
		reload: 0,
		bookingBuffer: config.bookingBuffer,
		weekendsOn: config.weekendsOn
	} as CalendarViewProps;

	switch (config.type) {
		case "me":
			props.sinkIn = !props.selectable;
			break;

		case "team":
			props.sinkIn = !props.selectable;
			break;

		case "office-hour":
			props.sinkIn = true;
			break;
	}

	if (config.duration) {
		props.canSelect = (args: CellArgs): boolean => {
			for (let i = 1; i < args.span; i++) {
				const event = args.blocks.get(args.block + i),
					occupied = (event instanceof EntryTimeEvent) ?
						false :
						Boolean(event)

				if (occupied || !args.isAvailable(args.block + i))
					return false;
			}

			return true;
		};
	}

	return props;
};

const requestEvent = async (
	link: string,
	event: TimeEvent,
	timeZone: string,
	formData: any
): Promise<boolean> => {
	const payload: BookingRequestPayload = {
		// User details
		details: {
			name: formData.name,
			company: formData.company,
			email: formData.email,
			phone: formData.phone,
			venue: formData.venue,
			description: formData.description
		},
		// Event information
		start: new Date(event.start).toISOString(),
		end: new Date(event.end).toISOString(),
		link: link || "",
		timeZone

	};

	const response = await request({
		url: `book/${link}/create`,
		method: "POST",
		body: payload
	});

	return response.success;
};

const getForm = (
	event: TimeEvent,
	config: BookingConfig,
	onSubmit: (values: any, ...restArgs: any[]) => void
) => {

	const detailsTip = config.description ?
		(
			<FormTip
				title={undefined}
			>
				{config.description}
			</FormTip>
		) :
		null;

	const company = config.type === "office-hour" ?
		(
			<FormCell>
				<label
					htmlFor="company"
					className="required"
				>
					Company Name
				</label>
				<Text
					required
					name="company"
				/>
			</FormCell>
		) :
		null;

	const venue = !config.venue ?
		(
			<FormCell>
				<label
					htmlFor="venue"
					className="required"
				>
					Conferencing Preference
				</label>
				<Select
					required
					name="venue"
					options={VENUE_OPTIONS}
				/>
			</FormCell>
		) :
		null;
	//@ts-ignore
	const Description = ({field, form: {touched, errors}, ...props}) => {
		return (
			<FormCell>
				<label
					htmlFor="description"
					className={config.requireDescription ? "required" : ""}
				>
					Additional Information
				</label>
				<TextArea
					required={config.requireDescription}
					name="description"
				/>
				{touched[field.name] && errors[field.name] && <FormError>{errors[field.name]}</FormError>}
			</FormCell>
		);
	}
		

	const validateDescription = (value:any) => {
		let error;

		if (value.length < 10) error = 'Descrip†ion must be at least 10 characters.'

		return error;
	}
		
	return (
		<Formik
			initialValues={{
				name: "",
				company: "",
				email: "",
				venue: "",
				phone: "",
				description: "",
				acceptPrivacy: false,
				staticLink: event.referenceId,
			}}
			onSubmit={onSubmit}
		>
			<Form>
				<FormCell>
					<label
						htmlFor="name"
						className="required"
					>
						Full Name
					</label>
					<Text
						required
						name="name"
					/>
				</FormCell>
				{company}
				<FormCell>
					<label
						htmlFor="email"
						className="required"
					>
						Email Address
					</label>
					<Text
						required
						name="email"
						pattern=".+@.+\..+"
						title="Email format: xx@yy.zz"
					/>
				</FormCell>
				{venue}
				<ConditionalPhoneField config={config}/>
				{detailsTip}
				<Field name='description' validate={validateDescription} component={Description}/>
				<TzNotice event={event}/>
				{/* User must accept Privacy Policy before being able to submit data */}
				<FormCell>
				<Check
					rawLabel="I have read and accept the <a href='https://www.paloaltonetworks.com/legal-notices/privacy' target='_blank'>Privacy Notice</a>."
					name="acceptPrivacy"
				></Check>
				</FormCell>

				<Centered>
					<SubmitButton config={config}/>

				</Centered>
			</Form>
		</Formik>
	);
};

const ConditionalPhoneField = (
	props: ConditionalPhoneFieldProps
): JSX.Element | null => {
	const ctx = useFormikContext();

	if (props.config.venue !== "phone" && (ctx.values as any).venue !== "phone")
		return null;

	return (
		<FormCell>
			<label
				htmlFor="phone"
				className="required"
			>
				Phone Number
			</label>
			<Text
				required
				name="phone"
				type="tel"
			/>
		</FormCell>
	);
};



const SubmitButton = (
	props: ConditionalPhoneFieldProps
): JSX.Element => {
	const ctx = useFormikContext();

	const accepted = (props.config.acceptPrivacy || (ctx.values as any).acceptPrivacy);

	return (
		<Button
			className="mt30"
			type="submit"
			disabled={!accepted}
		>
			Confirm Event Details
		</Button>
	);
};

const Book = () => {
	const {link} = useParams() as { link: string };

	const [fetches, setFetches] = useState(0);
	const [reload, setReload] = useState(0); // reload calendar
	const [selection, setSelection] = useState([] as TimeEvent[]);

	const [status, setStatus] = useState(LoadingStatuses.IDLE);
	const [expanded, setExpanded] = useState(false);
	const [confirmed, setConfirmed] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [bookingConfig, setBookingConfig] = useState(null as BookingConfig | null);
	const [selectedTopic, setSelectedTopic] = useState(null);

	const timeZone = useAppSelector(state => state.client.timeZone);
	const referenceTimeZone = useAppSelector(state => state.client.referenceTimeZone);

	const dispatch = useAppDispatch();
	const clickEvent = useGAEventsTracker('Bookings');

	const event = selection.length ?
		selection[0] :
		null;

	useEffect(() => {
		const fetchSchedule = async (): Promise<void> => {
			setStatus(LoadingStatuses.LOADING);

			const response = await fetchBookingConfig(link);

			if (typeof response == "string") {
				setStatus(LoadingStatuses.ERROR);
				setErrorMessage(response);
			} else {
				setBookingConfig(response);
				setStatus(LoadingStatuses.LOADED);
				dispatch(setReferenceTimeZone(response.referenceTimeZone));
			}
		};

		fetchSchedule();
	}, [fetches]);

	const selectEvent = (evt: SelectEvent): void => {
		setSelection([evt.event]);
		clickEvent('Customer Selected Booking Event');
		setTimeout(() => {
			setExpanded(true);
		}, 150);
	};

	const updateEvent = (evt: UpdateEvent): void => {
		clickEvent('Customer Updated Booking Event');
		setSelection([evt.event]);
	};

	const cancelEvent = (): void => {
		clickEvent('Customer Cancelled Booking Event');
		setExpanded(false);
		setSelection([]);
	};

	// Elements
	let menuLeft = null,
		sidebarContent = null,
		content = null,
		submitOverlay = null;

	if (event) {
		sidebarContent = getForm(event, bookingConfig!, async values => {
			setSubmitting(true);

			const success = await requestEvent(
				link,
				event,
				timeZone,
				values
			);

			if (success) {
				setConfirmed(true);
				clickEvent('Customer Requests Booking');
				setTimeout(() => setSubmitting(false), 1000);
			} else
				setSubmitting(false);
		});
	}

	switch (status) {
		case LoadingStatuses.IDLE:
			menuLeft = (
				<MenuLeft>
					<LoadingMessage>Idle</LoadingMessage>
				</MenuLeft>
			);

			content = (
				<Centered>
					Hit the refresh button to load scheduled meetings
				</Centered>
			);
			break;

		case LoadingStatuses.LOADING:
			menuLeft = (
				<MenuLeft>
					<LoadingMessage>Loading...</LoadingMessage>
				</MenuLeft>
			);

			content = (
				<Centered>
					<LoadingIcon/>
				</Centered>
			);
			break;

		case LoadingStatuses.ERROR:
			menuLeft = (
				<MenuLeft>
					<LoadingMessage>Loading failed</LoadingMessage>
				</MenuLeft>
			);

			content = (
				<Centered>
					{errorMessage || "Failed to fetch scheduled meetings"}
				</Centered>
			);
			break;

		case LoadingStatuses.LOADED: {
			const cvProps = resolveCalendarViewProps(
				bookingConfig!,
				timeZone,
				referenceTimeZone
			);

			const onTopicChange = (e: FormEvent<Option<any>>)=> {
				const newValue = e.value;
				setSelectedTopic(newValue);
				clickEvent('Customer Changed Topic Calendar');
				if (bookingConfig) {

					for (const evt of bookingConfig.events) {
						if (!newValue)
							evt.showInGui = true;
						else
							evt.showInGui = evt.topic==newValue;
					}
					setBookingConfig(bookingConfig);
					setReload(reload+1);

				}
			}
			menuLeft = (
				<MenuLeft>
					<Label title="Now booking">{bookingConfig!.name}</Label>
					<Tip>{bookingConfig!.tip}</Tip>
					<Select
						accented
						name="topic"
						options={TOPIC_OPTIONS}
						onChange={onTopicChange}
						value={selectedTopic}
					/>
				</MenuLeft>
			);

			content = (
				<CalendarView
					{...cvProps}
					autoScroll={true}
					reload={reload}
					selection={selection}
					onSelect={selectEvent}
					onUpdate={updateEvent}
					onRemove={cancelEvent}
					onTimeZoneSelect={evt => dispatch(setTimeZone(evt.value))}
					onReferenceTimeZoneSelect={evt => dispatch(setReferenceTimeZone(evt.value))}
				/>
			);
			break;
		}
	}

	if (submitting) {
		submitOverlay = (
			<LoadingScreen>
				<LoadingIcon/>
			</LoadingScreen>
		);
	}

	return (
		<Wrapper>
			<ContentWrapper>
				<MenuBar>
					{menuLeft}
					<MenuRight>
						<TZControls>
							You are in
							<TzSelect
								compact
								name="tz-select-self"
								value={timeZone}
								onChange={({ value }) => dispatch(setTimeZone(value))}
							/>
							Team is in
							<TzSelect
								alt
								compact
								name="tz-select-team"
								value={referenceTimeZone}
								onChange={({ value }) => dispatch(setReferenceTimeZone(value))}
							/>
						</TZControls>
						<Button
							square
							onClick={() => setFetches(fetches + 1)}
						>
							Refresh
						</Button>
					</MenuRight>
				</MenuBar>
				<Content>
					{content}
				</Content>
			</ContentWrapper>
			<SidebarWrapper>
				<Sidebar
					event={selection[0]}
					expanded={expanded}
				>
					<SidebarHeader>
						<SidebarTitle>Configure Selected Event</SidebarTitle>
						<CloseButton
							onClick={cancelEvent}
							tabIndex={expanded ? 0 : -1}
						>
							<Icon name="cross" />
						</CloseButton>
					</SidebarHeader>
					<SidebarContent>
						{sidebarContent}
						{submitOverlay}
					</SidebarContent>
				</Sidebar>
			</SidebarWrapper>
			<ConfirmationScreen expanded={confirmed}>
				<ConfirmationMessage expanded={confirmed}>
					<div>
						<Icon name="success-badge" />
					</div>
					<div>
						<h1>You’re almost there!</h1>
						<p>To finish scheduling your event, please go to your inbox and click the link provided in the confirmation email. The event time you requested will be held for 2 hours.</p>
					</div>
				</ConfirmationMessage>
			</ConfirmationScreen>
		</Wrapper>
	);
};

export default Book;
