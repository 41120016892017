import { Link } from "react-router-dom";
import { transparentize } from "polished";
import styled, { css } from "styled-components";

interface ButtonProps {
	to?: string;
	square?: boolean;
	[key: string]: any;
}

interface StyleProps {
	square: boolean;
	[key: string]: any;
}

const buttonStyle = css<StyleProps>`
	position: relative;
	padding: 8px 15px;
	background: ${p => p.theme.popBackground};
	font: inherit;
	font-weight: bold;
	letter-spacing: 0.02em;
	color: ${p => p.theme.popContrastColor};
	border: none;
	border-radius: ${p => p.square ? p.theme.borderRadius : "50px"};
	outline: none;
	cursor: pointer;
	user-select: none;
	z-index: 10;
	
	&:disabled,
	&.disabled {
		color: ${p => p.theme.popContrastColorDisabledLight};
		background: ${p => p.theme.popBackgroundDisabled};
		cursor: default;
		pointer-events: none;
	}
	
	&:focus {
		box-shadow: 0 0 0 4px ${p => transparentize(0.6, p.theme.popBackground)};
	}
`;

const StyledLink = styled(Link)<StyleProps>`
	${buttonStyle};
`;

const StyledButton = styled.button<StyleProps>`
	${buttonStyle};
`;

const Button = (props: ButtonProps) => (
	props.to ?
		<StyledLink {...props}>
			{props.children}
		</StyledLink> :
		<StyledButton {...props}>
			{props.children}
		</StyledButton>
);

export default Button;
