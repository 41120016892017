import styled from "styled-components";

import Button from "../components/button";
import Centered from "../components/centered";

const TestSections = styled.section`
	display: flex;
`;

const TestSection = styled.article`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-grow: 1;
	flex-basis: 0;

	& + & {
		border-left: 1px solid ${p => p.theme.separatorColor};
		padding-left: 40px;
		margin-left: 40px;
	}
`;

const TestContent = styled.div``;

const TestHeading = styled.h2`
	font-size: 130%;
	margin: 0;
`;

const TestDescription = styled.p`
	margin: 0.5em 0 2em;
	max-width: 250px;
`;

const Test = () => {
	const populate = () => {
		localStorage.clear();
		window.location.href = "/account";
	};

	const reset = () => {
		localStorage.clear();
		window.location.href = "/";
	};

	return (
		<Centered>
			<TestSections>
				<TestSection>
					<TestContent>
						<TestHeading>Populate Test Data</TestHeading>
						<TestDescription>
							This action will fill client-side storage with anonymous test data for testing of employee account configuration.
						</TestDescription>
					</TestContent>
					<Button
						square
						onClick={populate}
					>
						Populate
					</Button>
				</TestSection>
				<TestSection>
					<TestContent>
						<TestHeading>Reset All</TestHeading>
						<TestDescription>
							This action will clear all client-side storage and return to the sign-in page.
						</TestDescription>
					</TestContent>
					<Button
						square
						onClick={reset}
					>
						Reset
					</Button>
				</TestSection>
			</TestSections>
		</Centered>
	);
};

export default Test;
