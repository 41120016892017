import {
	useEffect,
	useMemo,
	useState
} from "react";
import styled from "styled-components";

import useSelectable, { Selectable } from "../../hooks/use-selectable";

import wrap from "./core/wrap";

import DropBox, {
	DropMeta,
	DropComponentProps
} from "./core/drop-box";
import Icon from "../icon";

import {
	Option,
	CompiledOption,
	WrappedInputProps
} from "../../types/forms";

interface SelectProps<V> {
	options: V[] | Option<V>[];
	min?: number;
	max?: number;
	hash?: (option: CompiledOption<V>) => string;
	multi?: boolean;
	autoSet?: boolean;
	detached?: boolean;
	persistentDrop?: boolean;
	option?: (props: OptionComponentProps<V>) => JSX.Element | string;
	showcase?: (props: ShowcaseComponentProps<V>) => JSX.Element | string;
	bareShowcase?: (props: ShowcaseComponentProps<V>) => JSX.Element;
}

interface DropProps<V> {
	expanded: boolean;
	selectable: Selectable<V>;
	expand: () => void;
	collapse: () => void;
}

interface HeadWrapperProps<V> extends WrappedInputProps<V> {
	dropMeta: DropMeta;
	type: string;
}

interface OptionWrapperProps {
	selected: boolean;
}

interface DropIconProps {
	expanded: boolean;
}

interface CustomComponentBaseProps<V> extends ComponentProps<V> {
	selectable: Selectable<V>;
}

export interface OptionComponentProps<V> extends CustomComponentBaseProps<V> {
	icon?: JSX.Element | null;
	option: CompiledOption<V>;
}

export interface ShowcaseComponentProps<V> extends CustomComponentBaseProps<V> {
	selection: Option<V>[];
}

type ComponentProps<V> = DropComponentProps<V, SelectProps<V>, DropProps<V>>;

const HeadWrapper = styled.button<HeadWrapperProps<any>>`
	${p => p.baseStyle};
	display: flex;
	justify-content: space-between;
	flex-grow: 1;
	align-items: center;
	border-radius: ${p => p.dropMeta.headBorderRadius.composite};
	cursor: pointer;
`;

const Placeholder = styled.span`
	font-style: italic;
	opacity: 0.6;
`;

const DropIcon = styled.div<DropIconProps>`
	width: 0.75em;
	height: 0.75em;
	border: 1px solid;
	margin-left: 1.5em;
	border-top: none;
	border-left: none;
	transform-origin: 65% 65%;
	transform: ${p => 
		p.expanded ?
			"translateY(0) rotate(-135deg)" :
			"translateY(-0.1em) rotate(45deg)"
	};
	transition: transform 300ms;
	opacity: 0.7;
`;

const DefaultDisplayContent = styled.div`
	display: flex;
	align-items: center;
`;

const CompanionIcon = styled(Icon)`
	width: 16px;
	height: 16px;
	margin: 0 8px 0 -2px;
	color: ${p => p.theme.extraLightColor};
`;

function SelectHead<V>(props: ComponentProps<V>) {
	const selectable = props.dropProps.selectable;
	let content;

	const icon = selectable.selection[0]?.icon ?
		<CompanionIcon name={selectable.selection[0].icon} /> :
		null;

	if (typeof props.inputProps.bareShowcase == "function") {
		return (
			<props.inputProps.bareShowcase
				{...props}
				selectable={props.dropProps.selectable}
				selection={props.dropProps.selectable.selection}
			/>
		);
	}

	if (typeof props.inputProps.showcase == "function") {
		content = props.inputProps.showcase({
			...props,
			selectable: props.dropProps.selectable,
			selection: props.dropProps.selectable.selection
		});
	} else {
		content = selectable.selection.length ?
			(
				<DefaultDisplayContent>
					{icon}
					{selectable.selection[0].label}
				</DefaultDisplayContent>
			):
			<Placeholder>No selection</Placeholder>;
	}

	return (
		<HeadWrapper
			{...props.inputProps as any}
			dropMeta={props.dropMeta}
			type="button"
			onClick={() => props.dropProps.expand()}
		>
			{content}
			<DropIcon expanded={props.dropProps.expanded} />
		</HeadWrapper>
	);
}

const BodyWrapper = styled.div`
	border: ${p => p.theme.boundaryBorder};
	background: ${p => p.theme.cardBackground};
	border-radius: inherit;
	overflow: auto;
`;

const OptionWrapper = styled.div<OptionWrapperProps>`
	background: ${p => p.selected ? p.theme.selectedBackground : "transparent"};
	cursor: pointer;
	
	& + & {
		border-top: ${p => p.theme.boundaryBorder}
	}
`;

const DefaultOptionContent = styled.div`
	display: flex;
	align-items: center;
	padding: 8px 12px;
`;

function SelectBody<V>(props: ComponentProps<V>) {
	const iProps = props.inputProps,
		dProps = props.dropProps,
		persistentDrop = iProps.persistentDrop;

	const dispatch = (evt: any, idx: number) => {
		if (iProps.multi)
			dispatchMultiple(evt, idx);
		else
			dispatchSingular(evt, idx);

		if (!persistentDrop)
			dProps.collapse();
	};

	const dispatchSingular = (evt: any, idx: number) => {
		iProps.dispatchBubble({
			type: "change",
			value: dProps.selectable.rawOptions[idx],
			originalEvent: evt
		});
	};

	const dispatchMultiple = (evt: any, idx: number) => {
		const activeOption = dProps.selectable.options[idx];
	};

	const options = dProps.selectable.options.map((option, i) => {
		const icon = option.icon ?
			<CompanionIcon name={option.icon} /> :
			null;

		const content = typeof iProps.option == "function" ?
			iProps.option({
				...props,
				selectable: props.dropProps.selectable,
				option,
				icon
			}) :
			(
				<DefaultOptionContent>
					{icon}
					{option.label}
				</DefaultOptionContent>
			);

		return (
			<OptionWrapper
				className="ow"
				key={i}
				selected={option.selected}
				onClick={(evt: any) => dispatch(evt, i)}
			>
				{content}
			</OptionWrapper>
		);
	});

	return (
		<BodyWrapper>
			{options}
		</BodyWrapper>
	);
}

function SelectComponent<V>(props: WrappedInputProps<V, SelectProps<V>>) {
	const [expanded, setExpanded] = useState(false);

	const selection = useMemo(
		() => [props.value],
		[props.value]
	) as V[];

	const selectable = useSelectable({
		options: props.options,
		selection,
		hash: props.hash,
		autoSet: props.autoSet
	});

	useEffect(
		() => {
			if (selectable.rawSelection[0] === selection[0])
				return;

			if (!props.multi) {
				props.dispatchBubble({
					type: "change",
					value: selectable.rawOptions[0],
					originalEvent: null
				});
			}
		},
		[selectable]
	);

	const dropProps = {
		expanded,
		selectable,
		expand: () => setExpanded(true),
		collapse: () => requestAnimationFrame(() => setExpanded(false))
	} as DropProps<V>;

	return (
		<DropBox
			className={props.className}
			inputProps={props}
			dropProps={dropProps}
			expanded={expanded}
			head={SelectHead}
			body={SelectBody}
			margin={20}
			gap={props.detached ? 5 : -1}
			borderRadius={3}
			onFocus={() => setExpanded(true)}
			onBlur={() => setExpanded(false)}
		/>
	);
}

const Select = wrap({
	observe: ["change"],
	component: SelectComponent
});

export default Select;
