import {
	useEffect,
	useContext,
	SyntheticEvent
} from "react";
import { css } from "styled-components";
import { FormikContext } from "formik";
import { transparentize } from "polished";

import CopyBox from "./copy-box";

import {
	Sender,
	FormEvent,
	InputProps,
	Dispatcher,
	FormEventType,
	WrapperConfig,
	InputComponent,
	WrappedInputProps,
	StagedEventHandler,
	FormEventHandlerName,
	ActionableFormEventType
} from "../../../types/forms";

const EVENT_TYPE_MAP: Record<FormEventHandlerName, FormEventType> = {
	onChange: "change",
	onClick: "click",
	onCopy: "copy",
	onFocus: "focus",
	onBlur: "blur"
};

const EVENT_HANDLER_NAME_MAP: Record<FormEventType, FormEventHandlerName> = {
	change: "onChange",
	click: "onClick",
	copy: "onCopy",
	focus: "onFocus",
	blur: "onBlur"
};

const ACTIONABLE_EVENT_MAP: Record<ActionableFormEventType, boolean> = {
	change: true
};

const baseStyle = css<WrappedInputProps<any, any>>`
	padding: ${p => p.copyable ? "8px 30px 8px 12px" : "8px 12px"};
	background: ${p => p.accented ? p.theme.cardBackground : p.theme.background};
	border: ${p => p.accented ? p.theme.boundaryBorder : "none"};
	border-radius: ${p => p.theme.borderRadius};
	outline: none;
	font: inherit;
	color: inherit;

	&:focus {
		box-shadow: 0 0 0 3px ${p => transparentize(0.5, p.theme.popBackground)};
		border-color: ${p => p.accented ? p.theme.popBackground : "transparent"};
	}
	
	&::placeholder {
		color: ${p => p.theme.lightColor};
	}
`;

// Produces an input wrapper that handles and wraps event handlers,
// interfacing with Formik if within a Formik context
// It also supplies base styling and a dispatcher method to
// send values for centralized handling (e.g. Formik)
function wrap<V, P, H>(config: WrapperConfig<V, P, H>) {
	return (props: InputProps<V, P>) => {
		const ctx = useContext(FormikContext),
			hasPropValue = props.hasOwnProperty("value");
		let value = hasPropValue ?
			props.value :
			null;

		// Validate props as Formik inputs and their
		// bare counterparts have different requirements
		if (ctx) {
			if (typeof props.name != "string") {
				console.error("Cannot use wrapped input: name is not provided");
				return <></>;
			}

			if (!hasPropValue)
				value = ctx.values[props.name];
		}

		useEffect(
			() => {
				if (!ctx)
					return;

				ctx.registerField(props.name!, {
					validate: props.validate
				});

				return () => ctx.unregisterField(props.name!);
			},
			[]
		);

		const dispatch = (
			typeOrFormEvent: FormEventType | Partial<Omit<FormEvent<V>, "isFormEvent">>,
			formEventOrValue?: FormEvent<V> | any
		): FormEvent<V> => {
			const formEvent = resolveFormEvent(
				props.name,
				typeOrFormEvent,
				formEventOrValue
			);

			// If within a Formik form, dispatch value
			if (ctx && ACTIONABLE_EVENT_MAP.hasOwnProperty(formEvent.type))
				ctx.setFieldValue(props.name!, formEvent.value);

			return formEvent;
		};

		const dispatchBubble = (
			typeOrFormEvent: FormEventType | Partial<Omit<FormEvent<V>, "isFormEvent">>,
			formEventOrValue?: FormEvent<V> | any
		) => {
			const evt = dispatch(typeOrFormEvent, formEventOrValue),
				handlerName = EVENT_HANDLER_NAME_MAP[evt.type];

			if (typeof props[handlerName] == "function")
				props[handlerName]!(evt);

			return evt;
		};

		const p = {
			...props,
			value,
			baseStyle,
			dispatch,
			dispatchBubble
		} as WrappedInputProps<V, P, H>;

		for (const k in props) {
			if (!props.hasOwnProperty(k))
				continue;

			const item = props[k as keyof InputProps<V, P>];

			const key = k as keyof WrappedInputProps<V, P, H>;

			// Wrap event handlers
			if (typeof item == "function" && k.indexOf("on") === 0) {
				p[key] = wrapEventHandler(
					props.name || "",
					k as FormEventHandlerName,
					dispatchBubble
				);
			} else {
				// @ts-ignore
				p[key] = item;
			}
		}

		// Observe events that are required to dispatch values
		for (const type of config.observe) {
			const name = EVENT_HANDLER_NAME_MAP[type];

			if (!p.hasOwnProperty(name)) {
				(p as any)[name] = wrapEventHandler(
					props.name || "",
					name,
					dispatchBubble
				);
			}
		}

		if (props.copyable) {
			return (
				<CopyBox
					input={config.component as InputComponent<V, P, H>}
					inputProps={p as any}
				/>
			);
		}

		return (
			<config.component
				id={props.name}
				{...p}
			/>
		);
	};
}

function resolveFormEvent<V>(
	name: string | undefined,
	typeOrFormEvent: FormEventType | Partial<Omit<FormEvent<V>, "isFormEvent">>,
	formEventOrValue?: FormEvent<V> | any
): FormEvent<V> {
	// If formEventOrValue has an "isFormEvent" field, then we
	// know the form event has already been resolved
	if (formEventOrValue && formEventOrValue.isFormEvent)
		return formEventOrValue;

	let formEvent: FormEvent<V>;

	if (typeof typeOrFormEvent == "string") {
		formEvent = {
			type: typeOrFormEvent,
			name: name || "",
			value: formEventOrValue,
			originalEvent: null,
			isFormEvent: true
		};
	} else {
		formEvent = {
			type: typeOrFormEvent.type || "change",
			name: name || "",
			value: typeOrFormEvent.value!,
			originalEvent: typeOrFormEvent.originalEvent || null,
			isFormEvent: true
		};
	}

	return formEvent;
}

function wrapEventHandler<V>(
	name: string,
	handlerName: FormEventHandlerName,
	dispatch: Dispatcher<V>
): StagedEventHandler<V> {
	const staged: StagedEventHandler<V> = (
		eventOrValue: Event | SyntheticEvent | FormEvent<V> | V
	) => {
		let rawFormEvent: Partial<Omit<FormEvent<V>, "isFormEvent">>;

		if ((eventOrValue as FormEvent<V>).isFormEvent)
			rawFormEvent = eventOrValue as FormEvent<V>;
		else if (eventOrValue instanceof Event || (eventOrValue as any)?.target instanceof Element) {
			rawFormEvent = {
				type: EVENT_TYPE_MAP[handlerName],
				value: (eventOrValue as any).target.value as V,
				originalEvent: eventOrValue as Event | SyntheticEvent
			};
		} else {
			rawFormEvent = {
				type: EVENT_TYPE_MAP[handlerName],
				value: eventOrValue as V
			};
		}

		const formEvent = resolveFormEvent(name, rawFormEvent);

		if (staged._as)
			formEvent.type = staged._as;
		if (staged._send)
			formEvent.value = staged._send(formEvent);

		dispatch(formEvent);
	};

	staged._as = null;
	staged._send = null;

	staged.as = (type: FormEventType): StagedEventHandler<V> => {
		staged._as = type;
		return staged;
	};

	staged.send = (
		send: Sender<V> | V
	): StagedEventHandler<V> => {
		staged._send = typeof send == "function" ?
			send as Sender<V> :
			() => send;

		return staged;
	};

	return staged;
}

export default wrap;

export {
	resolveFormEvent,
	wrapEventHandler
};
