// This utility class is used as a proxy for live local state
// that may be mutated, but offers more information for a
// user than a more basic solution would
class StateCapsule<T> {
	private state: T;

	constructor(state: T) {
		this.state = state;
	}

	set(state: T): void {
		this.state = state;
	}

	get(): T {
		return this.state;
	}
}

export default StateCapsule;
