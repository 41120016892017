import styled, { keyframes } from "styled-components";

interface WrapperProps {
	side: string;
}

const Figure = styled.figure`
	display: flex;
	position: relative;
`;

const leftAnimation = keyframes`
	to {
		transform: translateX(10%);
	}
`;

const rightAnimation = keyframes`
	to {
		transform: translateX(-10%);
	}
`;

const DiamondWrapper = styled.div<WrapperProps>`
	width: 20px;
	height: 20px;
	animation: ${p => p.side === "left" ? leftAnimation : rightAnimation} 600ms alternate infinite;
`;

const Diamond = styled.div`
	width: 100%;
	height: 100%;
	border: 0.2em solid ${p => p.theme.popBackground};
	transform: rotate(45deg);
`;

const LoadingIcon = () => (
	<Figure>
		<DiamondWrapper side="left">
			<Diamond />
		</DiamondWrapper>
		<DiamondWrapper side="right">
			<Diamond />
		</DiamondWrapper>
	</Figure>
);

export default LoadingIcon;
