import styled, { css } from "styled-components";
import { transparentize } from "polished";

import wrap from "./core/wrap";

import AspectBox from "../aspect-box";

import { WrappedInputProps } from "../../types/forms";

interface ToggleProps {
	value?: boolean;
	onClick?: (evt: any) => void;
}

const ToggleWrapper = styled.button`
	padding: 5px;
	width: 70px;
	outline: none;
	font: inherit;
	border: ${p => p.theme.boundaryBorder};
	border-radius: ${p => p.theme.borderRadius};
	background: ${p => p.theme.cardBackground};
	cursor: pointer;

	&:focus {
		border-color: ${p => p.theme.popBackground};
		box-shadow: 0 0 0 4px ${p => transparentize(0.6, p.theme.popBackground)};
	}
`;

const ToggleInnerWrapper = styled.div`
	position: relative;
	border-radius: inherit;
	background: ${p => p.theme.background};
`;

const ToggleNub = styled.div<ToggleProps>`
	position: absolute;
	top: 0;
	left: 0;
	width: 60%;
	height: 100%;
	border-radius: inherit;
	transform: ${p => p.value ? "translateX(67%)" : "none"};
	transition: transform 200ms;
	overflow: hidden;
`;

const labelCss = css`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	font-size: 85%;
	text-transform: uppercase;
	transition: transform 200ms, opacity 200ms;
	z-index: 10;
	
	&:before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: -100%;
		right: -100%;
		background: inherit;
		z-index: -1;
	}
`;

const OnLabel = styled.div<ToggleProps>`
	${labelCss};
	right: 0;
	color: ${p => p.theme.popContrastColor};
	background: ${p => p.theme.popBackground};
	transform: ${p => p.value ? "none" : "translateX(67%)"};
`;

const OffLabel = styled.div<ToggleProps>`
	${labelCss};
	left: 0;
	color: ${p => p.theme.popContrastColorDisabled};
	background: ${p => p.theme.popBackgroundDisabled};
	transform: ${p => p.value ? "translateX(-67%)" : "none"};
	opacity: ${p => p.value ? 0 : 1};
`;

const ToggleComponent = (props: WrappedInputProps<boolean, ToggleProps, "onClick">) => (
	<ToggleWrapper
		className="toggle"
		onClick={props.onClick.send(!props.value).as("change")}
		type="button"
	>
		<ToggleInnerWrapper>
			<AspectBox aspect={5 / 2}/>
			<ToggleNub {...props}>
				<OnLabel {...props}>On</OnLabel>
				<OffLabel {...props}>Off</OffLabel>
			</ToggleNub>
		</ToggleInnerWrapper>
	</ToggleWrapper>
);

const Toggle = wrap({
	observe: ["click"],
	component: ToggleComponent
});

export default Toggle;
