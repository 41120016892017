import {
	Route,
	Switch,
	Redirect
} from "react-router-dom";
import {
	SecureRoute,
	LoginCallback,
	useOktaAuth
} from "@okta/okta-react";

import { useAppSelector } from "../state/hooks";

import LoadingBox from "../components/loading-box";

// Routes
import UI from "./ui";
import Book from "./book";
import Test from "./test";
import Login from "./login";
import Account from "./account";
import Meeting from "./meeting";
import GoogleAuthorize from "./google-authorize";
import GoogleCallback from "./google-callback";

const Routes = () => {
	const { authState } = useOktaAuth();
	const employee = useAppSelector(state => state.client.employee);

	const index = employee && authState.isAuthenticated ?
		(
			<Redirect
				exact
				from="/"
				to="/account"
			/>
		) :
		(
			<Route exact path="/">
				<Login />
			</Route>
		);

	const account = employee ?
		<Account /> :
		null;

	return (
		<Switch>
			{index}
			<Route exact path="/okta/callback">
				<LoginCallback>
					<LoadingBox>Redirecting...</LoadingBox>
				</LoginCallback>
			</Route>
			<SecureRoute exact path="/google/authorize">
				<GoogleAuthorize />
			</SecureRoute>
			<SecureRoute exact path="/callback">
				<GoogleCallback />
			</SecureRoute>
			<Route exact path="/book/:link">
				<Book />
			</Route>
			<SecureRoute exact path="/account">
				{account}
			</SecureRoute>
			<Route exact path="/meeting/:link">
				<Meeting />
			</Route>
			<Route exact path="/ui">
				<UI />
			</Route>
			<Route exact path="/test">
				<Test />
			</Route>
		</Switch>
	);
};

export default Routes;
