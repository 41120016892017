import { isObject } from "./helpers";

import { API_URL } from "../data/constants";

import {
	Response,
	RequestInfo,
	LoadingState,
	RequestConfig,
	LoadingStateType
} from "../types/async";

const mkLoadingState = (type: LoadingStateType, message?: string): LoadingState => {
	const state = {
		idle: false,
		loading: false,
		success: false,
		errorMessage: ""
	} as LoadingState;

	switch (type) {
		case "idle":
			state.idle = true;
			return state;

		case "loading":
			state.loading = true;
			return state;

		case "success":
			state.success = true;
			return state;

		case "error":
			state.errorMessage = message || "Unspecified error";
			return state;
	}
};

const resolveRequest = (info: RequestInfo): Request => {
	if (typeof info == "string")
		return new Request(wrapUrl(info));

	const processedInfo = Object.assign({}, info);
	processedInfo.url = wrapUrl(processedInfo.url);

	if (isObject(processedInfo.body))
		processedInfo.body = JSON.stringify(processedInfo.body);

	if (processedInfo.query) {
		let q = processedInfo.query,
			u = new URL(processedInfo.url)

		for (const k in q) {
			if (q.hasOwnProperty(k))
				u.searchParams.set(k, q[k]);
		}

		processedInfo.url = u.href;
		delete processedInfo.query;
	}

	if (!processedInfo.method) {
		processedInfo.method = processedInfo.body ?
			"POST" :
			"GET";
	}

	const req = new Request(
		processedInfo.url,
		processedInfo as RequestConfig
	);

	if (processedInfo.body) {
		const contentType = req.headers.get("content-type");

		if (!contentType || contentType.indexOf("text/plain") !== -1)
			req.headers.set("content-type", "application/json");
	}

	return req;
};

const wrapUrl = (url: string): string => {
	let u = url.trim();
	if (isAbsoluteUrl(u))
		return u;

	u = mergeUrl(API_URL, u);
	if (isAbsoluteUrl(u))
		return u;

	return mergeUrl(window.location.origin, u);
};

const isAbsoluteUrl = (url: string): boolean => {
	return /^https?:\/\//.test(url);
};

const mergeUrl = (...components: string[]): string => {
	let out = "";

	for (const component of components) {
		const comp = component.trim();

		if (out && out[out.length - 1] !== "/" && comp[0] !== "/")
			out += "/";

		out += comp;
	}

	return out;
};

const request = async (info: RequestInfo): Promise<Response> => {
	let response,
		json;

	try {
		const req = resolveRequest(info);
		response = await fetch(req);
	} catch {
		return {
			data: null,
			success: false,
			errorMessage: "Network Error"
		};
	}

	try {
		json = await response.json();
	} catch {
		return {
			data: null,
			success: false,
			errorMessage: `Error ${response.status}`
		};
	}

	if (!json) {
		return {
			data: null,
			success: false,
			errorMessage: "Unknown Error"
		};
	}

	if (json.success) {
		return {
			data: json.data,
			success: true,
			errorMessage: null
		};
	}

	return {
		data: null,
		success: false,
		errorMessage: json.message
	};
};

export {
	mkLoadingState,
	resolveRequest,
	request
};
