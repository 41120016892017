import styled from "styled-components";

interface LabelProps {
	title: string;
	className?: string;
	children: any;
}

const LabelWrapper = styled.div`
	font-weight: bold;
	
	.label-title {
		font-size: 85%;
		font-weight: normal;
		opacity: 0.6;
	}
	
	&.time-label {
		min-width: 5em;
	}
`;

const Label = (props: LabelProps) => (
	<LabelWrapper className={props.className}>
		<div className="label-title">{props.title}</div>
		{props.children}
	</LabelWrapper>
);

export default Label;
