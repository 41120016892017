import styled from "styled-components";

import Icon from "./icon";

interface AvatarProps {
	picture: string | null;
	[key: string]: any;
}

const Wrapper = styled.div`
	width: 30px;
	height: 30px;
	background: ${p => p.theme.background};
	border-radius: 50%;
	overflow: hidden;
	box-shadow: 0 0 0 2px ${p => p.theme.boundaryBorderColor};
	
	svg {
		position: relative;
		left: -10%;
		top: 5%;
		width: 120%;
		height: 120%;
		fill: ${p => p.theme.popBackgroundSubdued};
		vertical-align: middle;
	}
	
	img {
		width: 100%;
		vertical-align: middle;
	}
`;

const Avatar = (props: AvatarProps) => {
	const picture = props.picture ?
		<img alt="User Profile Picture" src={props.picture} /> :
		<Icon name="user" />;

	return (
		<Wrapper {...props}>
			{picture}
		</Wrapper>
	);
};

export default Avatar;
