import {
	useState,
	useEffect
} from "react";
import { useHistory } from "react-router";

import {request} from "../util/async";

import { setEmployee } from "../state/features";
import { useAppDispatch } from "../state/hooks";

import Centered from "../components/centered";
import ErrorBox from "../components/error-box";
import LoadingBox from "../components/loading-box";

import { ClientEmployee } from "../../../types/employee";

interface CallbackResponse {
	success: boolean;
	data: ClientEmployee | null;
	message: string;
}

const callback = async (): Promise<CallbackResponse> => {
	const url = new URL(window.location.href);

	if (!url.searchParams.has("code")) {
		return {
			success: false,
			data: null,
			message: "No authorization code found"
		};
	}

	const response = await request({
		url: "auth/callback",
		query: {
			code: url.searchParams.get("code")
		}
	});

	if (response.success)
		return response as any;

	return {
		success: false,
		data: null,
		message: response.errorMessage!
	};
};

const GoogleCallback = () => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const [clientErrorMessage, setClientErrorMessage] = useState(null as string | null);

	useEffect(
		() => {
			const runCallback = async () => {
				// Clear client data to trigger a complete refresh
				// and to keep it in sync with the server
				localStorage.removeItem("client-data");

				const res = await callback();

				if (res.success) {
					dispatch(setEmployee(res.data));
					// Let localStorage state update
					setTimeout(() => history.replace("/account"), 150);
				} else
					setClientErrorMessage(res.message);
			};

			runCallback();
		},
		[]
	);

	let content;

	if (clientErrorMessage)
		content = <ErrorBox>{clientErrorMessage}</ErrorBox>
	else
		content = <LoadingBox>Authorizing User...</LoadingBox>;

	return (
		<Centered>
			{content}
		</Centered>
	);
};

export default GoogleCallback;
