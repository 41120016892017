import { configureStore } from "@reduxjs/toolkit";
import { client } from "./features";

let timeout: number = -1;

const store =  configureStore({
	reducer: {
		client
	},
	middleware: getDefaultMiddleware => {
		return getDefaultMiddleware()
			.concat(
				store => next => action => {
					if (action.type.indexOf("client") !== 0)
						return next(action);

					clearTimeout(timeout);

					// @ts-ignore
					timeout = setTimeout(() => {
						localStorage.setItem(
							"client-data",
							JSON.stringify(store.getState().client)
						);
					}, 50);

					return next(action);
				}
			);
	}
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
