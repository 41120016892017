import styled from "styled-components";

import { Time as ITime } from "../types/time";
import { getLocalizedTime } from "../util/time";

interface TimeProps {
	time: ITime | Pick<ITime, "hour" | "minute"> | Date;
	offset?: number;
	vertical?: boolean;
	centered?: boolean;
	className?: string;
}

interface WrapperProps {
	vertical?: boolean;
	centered?: boolean;
}

const TimeWrapper = styled.span<WrapperProps>`
	${p => p.vertical ? {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		lineHeight: 1.2
	} : null}
	${p => p.centered ? {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)"
	} : null}
	font-weight: bold;

	.meridiem {
		margin-left: ${p => p.vertical ? null : "0.1em"};
		font-size: ${p => p.vertical ? "80%" : "90%"};
		font-weight: normal;
		opacity: 0.8;
	}
`;

const Time = (props: TimeProps) => {
	let hour,
		minute;

	if (props.time instanceof Date) {
		let date = props.offset ?
			new Date(props.time.getTime() + props.offset * 1000) :
			props.time;

		hour = date.getHours();
		minute = date.getMinutes();
	} else {
		if (props.offset) {
			const time = getLocalizedTime(
				(props.time as ITime).date.getTime() + props.offset * 1000,
				(props.time as ITime).timeZone
			);

			hour = time.hour;
			minute = time.minute;
		} else {
			hour = props.time.hour;
			minute = props.time.minute;
		}
	}

	return (
		<TimeWrapper
			vertical={props.vertical}
			centered={props.centered}
			className={props.className}
		>
			<span>{hour % 12 || 12}{minute ? `:${minute >= 10 ? minute : `0${minute}`}` : ""}</span>
			<span className="meridiem">{hour < 12 ? "AM" : "PM"}</span>
		</TimeWrapper>
	);
};

export default Time;
