import styled from "styled-components";

import wrap from "./core/wrap";

import Icon from "../icon";

import { WrappedInputProps } from "../../types/forms";

interface CheckProps {
	label?: string;
	rawLabel?: string;
	small?: boolean;
	inverted?: boolean;
}

interface CheckBoxProps {
	checked: boolean;
	baseStyle: any;
	small?: boolean;
	inverted?: boolean;
}

const CheckWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const CheckBox = styled.button<CheckBoxProps>`
	${p => p.baseStyle};
	flex-grow: 0;
	padding: 0 3px;
	width: ${p => p.small ? "20px" : "22px"};
	height: ${p => p.small ? "20px" : "22px"};
	border-radius: ${p => p.theme.borderRadius};
	border: ${p => {
		if (p.inverted)
			return `1px solid ${p.theme.popContrastColor}`;
		
		return p.checked ?
			"none" :
			`1px solid ${p.theme.separatorColor}`
	}};
	background: ${p => p.checked !== Boolean(p.inverted) ? p.theme.popBackground : p.theme.cardBackground};
	color: ${p => p.inverted ? p.theme.popBackground : p.theme.popContrastColor};
	cursor: pointer;
`;

const CheckLabel = styled.label`
	margin-left: 8px !important;
	cursor: pointer;
	user-select: none;
`;

const CheckComponent = (props: WrappedInputProps<boolean, CheckProps, "onClick">) => {
	const icon = props.value ?
		<Icon name="check" /> :
		null;

	const label = props.label ?
		(
			<CheckLabel
				onClick={props.onClick.send(!props.value).as("change")}
			>
				{props.label}
			</CheckLabel>
		) :
		null;

	const rawLabel = props.rawLabel ?
		(
			<CheckLabel
				onClick={props.onClick.send(!props.value).as("change")}
				dangerouslySetInnerHTML={{__html: props.rawLabel}}
			>
			</CheckLabel>
		) :
		null;

	return (
		<CheckWrapper>
			<CheckBox
				type="button"
				role="checkbox"
				small={props.small}
				inverted={props.inverted}
				checked={Boolean(props.value)}
				baseStyle={props.baseStyle}
				onClick={props.onClick.send(!props.value).as("change")}
			>
				{icon}
			</CheckBox>
			{rawLabel || label}
		</CheckWrapper>
	);
};

const Check = wrap({
	observe: ["click"],
	component: CheckComponent
});

export default Check;
