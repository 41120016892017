import styled from "styled-components"

import Icon from "./icon";

interface ErrorBoxProps {
	children?: any;
}

const ErrorBoxWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const ErrorContent = styled.p`
	margin: 2em 0 0;
`;

const ErrorIcon = styled(Icon)`
	height: 30px;
	color: ${p => p.theme.popBackground};
`;

const ErrorBox = (props: ErrorBoxProps) => {
	const content = props.children ?
		<ErrorContent>{props.children}</ErrorContent> :
		null;

	return (
		<ErrorBoxWrapper>
			<ErrorIcon name="cross" />
			{content}
		</ErrorBoxWrapper>
	);
};

export default ErrorBox;
