import React from "react";
import ReactDOM from "react-dom";
import { Security } from "@okta/okta-react";
import { OktaAuth } from "@okta/okta-auth-js";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router } from "react-router-dom";

// Runtime and config
import reportWebVitals from "./reportWebVitals";
import CONFIG from "./data/okta-config";

// State
import store from "./state/store";

// Components
import App from "./app";

// Styles and themes
import "./index.scss";
import lightTheme from "./themes/light";

const AUTH = new OktaAuth(CONFIG.oidc);

ReactDOM.render(
	<React.StrictMode>
		<Security oktaAuth={AUTH}>
			<Provider store={store}>
				<ThemeProvider theme={lightTheme}>
					<Router>
						<App />
					</Router>
				</ThemeProvider>
			</Provider>
		</Security>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
