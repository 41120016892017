const isObject = (candidate: any): boolean => {
	if (!candidate || typeof candidate != "object")
		return false;

	const proto = Object.getPrototypeOf(candidate);
	return proto == null || proto === Object.prototype;
};

const splitName = (name = '') => {
	const [firstName, ...lastName] = name.split(' ').filter(Boolean);
	return {
		firstName: firstName,
		lastName: lastName.join(' ')
	}
}

export {
	isObject,
	splitName
};
