import {
	Time,
	Format,
	DaysMap,
	TimePart,
	TimeFrame,
	DateValue,
	CalendarDay,
	AugmentedTimeFrame
} from "../types/time";
import {
	TIMEZONES,
	PERIODICITY,
	DISPLAY_PERIODICITY
} from "../data/constants";

interface Frame {
	date: Date;
	timestamp: number;
	offset: number;
}

interface OffsetData {
	currentFrame: Frame;
	nextFrame: Frame | null;
	previousFrame: Frame | null;
}

interface OffsetCachePartition {
	[key: string]: any;
}

const HAS_OWN_IMPL = Object.hasOwnProperty;
const hasOwn = (o: any, k: string | symbol) => HAS_OWN_IMPL.call(o, k);

const OFFSETS: OffsetCachePartition[] = [];

function getLocalizedTime(dateValue: DateValue, timeZone?: string | null): Time {
	const sysDate = mkDate(dateValue),
		timestamp = sysDate.getTime(),
		offset = timeZone ?
			getOffsetData(sysDate, timeZone).currentFrame.offset :
			sysDate.getTimezoneOffset(),
		mappedDate = new Date(sysDate.getTime() - (offset * 60 * 1000));

	const out: Time = {
		timeZone: timeZone || printOffset(sysDate.getTimezoneOffset()),
		date: sysDate,
		dayCode: 0,
		dateString: "",
		dayIndex: 0,
		timestamp,
		epoch: Math.floor(timestamp / 1000),
		offset: offset,
		year: mappedDate.getUTCFullYear(),
		month: mappedDate.getUTCMonth(),
		day: mappedDate.getUTCDate(),
		hour: mappedDate.getUTCHours(),
		minute: mappedDate.getUTCMinutes(),
		second: mappedDate.getUTCSeconds()
	};

	const leapSecondAdjustment = (-(out.hour * 60 + out.minute) + 12 * 60) * 60 * 1000 - offset;

	out.dayCode = Math.floor(
		(mappedDate.getTime() + leapSecondAdjustment) / (24 * 60 * 60 * 1000)
	);
	out.dateString = `${
			out.year
		}-${
			padNum(out.month)
		}-${
			padNum(out.day)
		} ${
			padNum(out.hour)
		}:${
			padNum(out.minute)
		}:${
			padNum(out.second)
		} ${
			timeZone || printOffset(sysDate.getTimezoneOffset())
		}`;
	out.dayIndex = mappedDate.getUTCDay();

	return out;
}

// Get current time zone offset frame, as well as the ones preceding
// and succeeding it. These frames will be necessary when transposing dates
function getOffsetData(date: Date, timeZone: string): OffsetData {
	const year = date.getUTCFullYear(),
		timestamp = date.getTime(),
		frames = getOffsets(year, timeZone);
	let currentFrameIndex = -1;

	for (const frame of frames) {
		if (frame.timestamp > timestamp)
			break;

		currentFrameIndex++;
	}

	return {
		currentFrame: frames[currentFrameIndex],
		nextFrame: frames[currentFrameIndex + 1] || null,
		previousFrame: frames[currentFrameIndex - 1] || null
	};
}

// This function samples the first day of the month from each month from the provided year,
// starting from the previous year, and reaching into the next, to find where
// offsets change for any given time zone. The returned array contains the points where
// switches occur, so that correct time zone transforms can be performed quickly
function getOffsets(year: number, timeZone: string) {
	if (inCache(OFFSETS, year, timeZone))
		return OFFSETS[year][timeZone];

	const frames: Frame[] = [];
	let lastFrame: Frame | null = null;

	setCache(OFFSETS, year, timeZone, frames);

	const dtfConfig = {
		year: "numeric" as Format,
		month: "2-digit" as Format,
		day: "2-digit" as Format,
		hour: "2-digit" as Format,
		minute: "2-digit" as Format,
		second: "2-digit" as Format,
		timeZone: timeZone
	};

	const dtf = new Intl.DateTimeFormat("en-US", dtfConfig);

	for (let i = -1; i < 14; i++) {
		const frame = getFrame(dtf, Date.UTC(year, i));

		if (!lastFrame)
			frames.push(frame);
		else if (lastFrame.offset !== frame.offset)
			frames.push(getOffsetSwitch(dtf, lastFrame, frame));

		lastFrame = frame;
	}

	return frames;
}

// Resolve the exact timestamp where the time zone switches between two frames
// This is determined using a basic binary search, which is necessitated by
// the fact that DateTimeFormat.format is quite slow
function getOffsetSwitch(dtf: Intl.DateTimeFormat, fA: Frame, fB: Frame): Frame {
	let tsStart = fA.timestamp,
		tsEnd = fB.timestamp,
		frame = null;

	while (true) {
		if (tsEnd - tsStart <= 1000) {
			if (frame!.offset === fA.offset)
				return getFrame(dtf, frame!.timestamp + 1000);

			return frame as Frame;
		}

		frame = getFrame(dtf, Math.floor((tsStart + tsEnd) / 2));

		if (frame.offset === fA.offset)
			tsStart = frame.timestamp;
		else
			tsEnd = frame.timestamp;
	}
}

// Get a frame at a specified timestamp, rounded to the nearest second, and convert to UTC
// This way, the built-in Date utilities can be used to get accurate data without having
// to worry about time zone offsets or DST
function getFrame(dtf: Intl.DateTimeFormat, inTimestamp: number): Frame {
	const timestamp = Math.round((inTimestamp / 1000)) * 1000,
		date = new Date(timestamp),
		formatted = dtf.format(date),
		converted = new Date(`${formatted} UTC`);

	return {
		date,
		timestamp,
		offset: Math.round(
			(date.getTime() - converted.getTime()) / (60 * 1000)
		)
	};
}

function transposeDate(utcDate: Date, timeZone: string) {
	const timestamp = utcDate.getTime(),
		offsetData = getOffsetData(utcDate, timeZone);
	let shiftedTimestamp = timestamp + offsetData.currentFrame.offset * 60 * 1000;

	if (offsetData.previousFrame && shiftedTimestamp < offsetData.currentFrame.timestamp)
		shiftedTimestamp = timestamp + offsetData.previousFrame.offset * 60 * 1000;
	else if (offsetData.nextFrame && shiftedTimestamp >= offsetData.nextFrame.timestamp)
		shiftedTimestamp = timestamp + offsetData.nextFrame.offset * 60 * 1000;

	return new Date(shiftedTimestamp);
}

const TIME_PART_KEYS = [
	"year",
	"month",
	"day",
	"hour",
	"minute",
	"second",
	"milli"
] as TimePart[];

function mkDate(...args: DateValue[]) {
	if (!args.length || args[0] == null)
		return new Date();

	let numericArgs = [],
		timeZone = null;

	for (let i = 0, l = args.length; i < l; i++) {
		const arg = args[i];

		if (typeof arg == "number")
			numericArgs.push(arg);
		else if (i) {
			if (typeof arg == "string")
				timeZone = arg;

			break;
		}
	}

	const d = {
		year: 0,
		month: 0,
		day: 1,
		hour: 0,
		minute: 0,
		second: 0,
		milli: 0
	};
	let dt = null as Date | null;

	if (Array.isArray(args[0]))
		numericArgs = args[0];

	if (numericArgs.length === 1)
		dt = new Date(numericArgs[0]);
	else if (args[0] instanceof Date)
		dt = args[0];
	else if (typeof args[0] == "string")
		dt = new Date(args[0]);
	else if (typeof args[0] == "object" && !Array.isArray(args[0]))
		Object.assign(d, args[0]);
	else {
		if (numericArgs.length > TIME_PART_KEYS.length)
			throw new Error(`Cannot make date: received extraneous numeric arguments (expected 1-${TIME_PART_KEYS.length}, got ${numericArgs.length})`);

		for (let i = 0, l = numericArgs.length; i < l; i++)
			d[TIME_PART_KEYS[i]] = numericArgs[i];
	}

	if (dt) {
		d.year = dt.getFullYear();
		d.month = dt.getMonth();
		d.day = dt.getDate();
		d.hour = dt.getHours();
		d.minute = dt.getMinutes();
		d.second = dt.getSeconds();
		d.milli = dt.getMilliseconds();
	}

	if (timeZone) {
		const utcDate = new Date(
			Date.UTC(d.year, d.month, d.day, d.hour, d.minute, d.second, d.milli)
		);

		return transposeDate(utcDate, timeZone);
	}

	return new Date(d.year, d.month, d.day, d.hour, d.minute, d.second, d.milli);
}

function printOffset(offset: number): string {
	const absOffset = Math.abs(offset);
	return `GMT${offset > 0 ? "-" : "+"}${padNum(Math.floor(absOffset / 60))}${padNum(absOffset % 60)}`;
}

function padNum(num: number): string {
	if (!num)
		return "00";
	if (num < 10)
		return "0" + num;

	return String(num);
}

function getDayHours(time: Time): number {
	return time.hour + (time.minute / 60) + (time.second / (60 * 60));
}

// Caching utilities
function inCache(
	cache: OffsetCachePartition[],
	year: number,
	timeZone: string
): boolean {
	return hasOwn(cache, String(year)) && hasOwn(cache[year], timeZone);
}

function setCache(
	cache: OffsetCachePartition[],
	year: number,
	timeZone: string,
	value: any
): void {
	if (!hasOwn(cache, String(year)))
		cache[year] = {};

	cache[year][timeZone] = value;
}

const mkTimeString = (hours: number, minutes: number): string => {
	return `${padNum(hours)}:${padNum(minutes)}`;
};

const parseTimeString = (timeString: string): [number, number] => {
	return timeString
		.split(":")
		.map(Number) as [number, number];
};

const printTime = (hours: number, minutes: number): string => {
	if (!minutes)
		return `${hours % 12 || 12} ${hours < 12 ? "AM" : "PM"}`;

	return `${hours % 12 || 12}:${minutes} ${hours < 12 ? "AM" : "PM"}`;
};

const mkDateString = (year: number, month: number, day: number): string => {
	return `${year}-${padNum(month)}-${padNum(day)}`;
};

const parseDateString = (dateString: string): [number, number, number] => {
	return dateString
		.split("-")
		.map(Number) as [number, number, number];
};

const printDate = (year: number, month: number, day: number): string => {
	return `${month}/${day}/${year}`;
};

// This test function proves that every day is spaced
// exactly 86400000 milliseconds apart in UTC
// We will use this fact to calculate a unique identifier
// for every 15-minute block in time

/*
const isUtcSpaced = (samples = 1e5) => {
	const times = [],
		diffs = [];

	for (let i = 1; i < samples; i++)
		times.push(Date.UTC(2000, 0, i));

	for (let i = 1, l = times.length; i < l; i++)
		diffs.push(times[i] - times[i - 1]);

	return diffs.every(v => v === 86400000);
};
*/

const UTC_2000 = Date.UTC(2000, 0, 1),
	BLOCK_SPAN = Math.round(PERIODICITY * 60) * 60 * 1000,
	SPAN_24_H = 24 * 60 * 60 * 1000,
	LEEWAY = 5 * 60 * 1000;

const getUtcBlock = (...args: DateValue[]) => {
	const time = mkDate(...args).getTime();
	return Math.floor((time - UTC_2000) / BLOCK_SPAN);
};

const getUtcDayCode = (...args: DateValue[]) => {
	const time = mkDate(...args).getTime();
	return Math.floor(time / SPAN_24_H);
};

const getClosestBlockStart = (...args: DateValue[]) => {
	const time = mkDate(...args).getTime(),
		extent = time % BLOCK_SPAN;

	// If the period is less than five minutes from the next
	// period, round up. Else, round down
	if (BLOCK_SPAN - extent < LEEWAY)
		return Math.ceil(time / BLOCK_SPAN) * BLOCK_SPAN;

	return Math.floor(time / BLOCK_SPAN) * BLOCK_SPAN;
};

const getClosestBlockEnd = (...args: DateValue[]) => {
	const time = mkDate(...args).getTime();
	// Always round up to avoid scheduling collisions
	return Math.ceil(time / BLOCK_SPAN) * BLOCK_SPAN;
};

const DAY_KEYS = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

const resolveTimeFrame = (frame: TimeFrame): AugmentedTimeFrame => {
	const out = {
		extent: frame.extent,
		timeZone: frame.timeZone,
		startDate: mkDate(frame.startDate).getTime(),
		dayStart: frame.dayStart || 0,
		dayEnd: frame.dayEnd || 24,
		periodicity: frame.periodicity || PERIODICITY,
		displayPeriodicity: frame.displayPeriodicity || DISPLAY_PERIODICITY,
		referenceTimeZone: frame.referenceTimeZone || null,
		presentationTimeZone: frame.presentationTimeZone || null
	} as Partial<AugmentedTimeFrame>;

	const days = {} as any;

	for (const key of DAY_KEYS) {
		days[key] = typeof (frame.days as any)[key] == "boolean" ?
			(frame.days as any)[key] :
			null;
	}

	out.days = days as DaysMap;

	return out as AugmentedTimeFrame;
};

const resolveCalendarDays = (frame: AugmentedTimeFrame): CalendarDay[] => {
	const nowTime = getLocalizedTime(frame.startDate, frame.timeZone),
		days = [] as CalendarDay[];
	let offset = 0,
		extent = frame.extent;

	while (extent) {
		const startTime = getLocalizedTime(
			mkDate(
				nowTime.year,
				nowTime.month,
				nowTime.day + offset,
				frame.dayStart,
				frame.timeZone
			),
			frame.timeZone
		);

		const endTime = getLocalizedTime(
			mkDate(
				nowTime.year,
				nowTime.month,
				nowTime.day + offset,
				frame.dayEnd,
				frame.timeZone
			),
			frame.timeZone
		);

		if (endTime.timestamp < nowTime.timestamp) {
			offset++;
			continue;
		}

		const dayDisplay = (frame.days as any)[DAY_KEYS[startTime.dayIndex]];

		if (!dayDisplay) {
			if (dayDisplay === null)
				extent--;

			offset++;
			continue;
		}

		days.push({
			startTime,
			endTime,
			startBlock: getUtcBlock(getClosestBlockStart(startTime.timestamp)),
			endBlock: getUtcBlock(getClosestBlockEnd(endTime.timestamp))
		});

		extent--;
		offset++;
	}

	return days;
};

const getTimeZoneShortName = (timeZone: string): string => {
	const tz = TIMEZONES.find(t => t.zone === timeZone);

	return tz ?
		tz.shortName :
		"";
};

const getLocalTimeZone = () => {
	const date = new Date(),
		offset = date.getTimezoneOffset(),
		zones = TIMEZONES.map(tz => ({
			...tz,
			proximity: Math.abs(getOffsetData(date, tz.zone).currentFrame.offset - offset)
		}))
			.sort((z, z2) => z.proximity - z2.proximity);

	return zones[0].zone;
};

export {
	getLocalizedTime,
	mkDate,
	getDayHours,
	mkTimeString,
	parseTimeString,
	printTime,
	mkDateString,
	parseDateString,
	printDate,
	getUtcBlock,
	getUtcDayCode,
	getClosestBlockStart,
	getClosestBlockEnd,
	resolveTimeFrame,
	resolveCalendarDays,
	getTimeZoneShortName,
	getLocalTimeZone
};
