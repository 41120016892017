import {
	useState,
	useEffect
} from "react";
import {
	AuthState,
	UserClaims
} from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";

import { request } from "../util/async";

import Centered from "../components/centered";
import ErrorBox from "../components/error-box";
import LoadingBox from "../components/loading-box";

import { setEmployee } from "../state/features";
import { useAppDispatch } from "../state/hooks";

import { ClientEmployee } from "../../../types/employee";

interface AuthResponse {
	success: boolean;
	data: ClientEmployee | string | null;
	message: string;
}

const authorize = async (
	claims: UserClaims,
	auth: AuthState
): Promise<AuthResponse> => {
	const response = await request({
		url: "auth/authorize",
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			authorization: auth.accessToken?.value || ""
		},
		body: {
			email: claims.email
		}
	});

	if (response.success)
		return response as any;

	return {
		success: false,
		data: null,
		message: response.errorMessage!
	};
};

const OktaCallback = () => {
	const {
		oktaAuth,
		authState
	} = useOktaAuth();

	const dispatch = useAppDispatch();

	const [clientErrorMessage, setClientErrorMessage] = useState(null as string | null);

	useEffect(
		() => {
			const runAuth = async () => {
				// Clear client data to trigger a complete refresh
				// and to keep it in sync with the server
				localStorage.removeItem("client-data");

				let user = null;

				try {
					user = await oktaAuth.getUser();
				} catch {
					setClientErrorMessage("Failed to resolve user");
					return;
				}

				const res = await authorize(user, authState);

				if (!res.success)
					setClientErrorMessage(res.message);
				else if (typeof res.data == "string")
					window.location.href = res.data;
				else {
					const em = res.data as ClientEmployee;
					dispatch(setEmployee(em));
					// Let localStorage state update
					setTimeout(() => window.location.href = "/account", 150);
				}
			};

			runAuth();
		},
		[oktaAuth]
	);

	let content;

	if (clientErrorMessage)
		content = <ErrorBox>{clientErrorMessage}</ErrorBox>
	else if (!authState.isPending && authState.error)
		content = <ErrorBox>{authState.error.message}</ErrorBox>
	else
		content = <LoadingBox>Redirecting...</LoadingBox>;

	return (
		<Centered>
			{content}
		</Centered>
	);
};

export default OktaCallback;
