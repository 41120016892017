import styled from "styled-components";

import wrap from "./core/wrap";

import { WrappedInputProps } from "../../types/forms";

interface TimeProps {
	step?: number;
}

const TimeWrapper = styled.input<WrappedInputProps<string>>`
	${p => p.baseStyle};
	height: 35px;
`;

const Text = wrap({
	observe: ["change"],
	component: (props: WrappedInputProps<string, TimeProps>) => (
		<TimeWrapper
			{...props}
			type="time"
		/>
	)
});

export default Text;
