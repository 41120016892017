import {
	useRef,
	useMemo,
	useState,
	useEffect
} from "react";
import styled, { withTheme } from "styled-components";
import { transparentize } from "polished";

import {
	DAYS,
	DAYS_SHORT,
	MONTHS_SHORT,
	DISPLAY_PERIODICITY
} from "../data/constants";

import {
	mkDate,
	getUtcBlock,
	resolveTimeFrame,
	getLocalizedTime,
	resolveCalendarDays,
	getTimeZoneShortName
} from "../util/time";
import TimeBlocks, {
	TimeEvent,
	EntryTimeEvent,
	TakenTimeEvent,
	TimeEventConfig,
	SelectableTimeEvent
} from "../util/time-blocks";

import { TzSelect } from "./inputs";
import Icon from "./icon";
import Time from "./time";

import {
	Time as ITime,
	TimeFrame,
	DateValue,
	CalendarDay,
	AugmentedTimeFrame
} from "../types/time";
import { FormEvent } from "../types/forms";
import { Availability } from "../../../types/employee";

export interface CalendarViewProps {
	// General
	frame: TimeFrame;
	events?: TimeEventConfig[];
	selection?: (TimeEventConfig | TimeEvent)[];
	eventConfig?: EventConfig;
	availability?: Availability | null;
	availabilityTimeZone?: string;
	bookingBuffer: number;
	weekendsOn: boolean;

	// Display
	fill?: boolean;
	sinkIn?: boolean;
	disabled?: boolean;
	daysOnly?: boolean;
	autoScroll?: boolean;
	editableTimeZones?: boolean;

	// Behavior
	resizable?: boolean;
	removable?: boolean;
	selectable?: boolean;
	canSelect?: (args: CellArgs) => boolean;
	isInScope?: (args: CellArgs) => boolean;
	reload: number;

	// Events
	onSelect?: (event: SelectEvent) => void;
	onUpdate?: (event: UpdateEvent) => void;
	onRemove?: (event: RemoveEvent) => void;
	onDaySelect?: (event: DaySelectEvent) => void;
	onTimeZoneSelect?: (event: FormEvent<string>) => void;
	onReferenceTimeZoneSelect?: (event: FormEvent<string>) => void;
}

interface ThemedCalendarViewProps extends CalendarViewProps {
	theme: any;
}

interface EventConfig {
	minDuration?: number;
	maxDuration?: number;
}

interface AugmentedEventConfig extends EventConfig {
	minDuration: number;
	maxDuration: number;
}

export interface SelectEvent {
	event: TimeEvent;
	day: CalendarDay;
}

export interface UpdateEvent {
	event: TimeEvent;
	oldEvent: TimeEvent;
	day: CalendarDay;
}

export interface RemoveEvent {
	event: TimeEvent;
	day: CalendarDay;
}

export interface DaySelectEvent {
	events: TimeEventConfig[];
	selection: TimeEvent[];
	day: CalendarDay;
}

export interface CellArgs {
	time: ITime;
	day: CalendarDay;
	block: number;
	blocks: TimeBlocks;
	span: number;
	duration: number;
	isAvailable: (...args: DateValue[]) => boolean;
}

interface Dimensions {
	width: number;
	height: number;
}

interface GridProps {
	dimensions: Dimensions;
	resizing: boolean;
	fillWidth?: boolean;
}

interface DayLabelProps {
	today?: boolean;
}

interface SpreadButtonProps {
	onDaySelect: () => void;
}

interface TimeHeaderProps {
	x: number;
	y: number;
	flush: boolean;
	gridDimensions?: Dimensions;
}

interface CellProps {
	x: number;
	y: number;
	disabled?: boolean;
	outOfScope?: boolean;
	gridDimensions?: Dimensions;
}

interface FixedCellProps {
	x: number;
	y: number;
	span: number;
	sinkIn?: boolean;
	disabled?: boolean;
	unavailable?: boolean;
}

interface SelectableCellProps extends FixedCellProps {
	titled: boolean;
	selected: boolean;
}

interface CellOverlayProps {
	x: number;
	y: number;
	dimensions: Dimensions;
}

interface Area {
	x: number;
	y: number;
	span: number;
	event: TimeEvent;
	day: CalendarDay;
}

interface TimeSpanProps {
	event: TimeEvent;
	frame: AugmentedTimeFrame;
	expanded?: boolean;
}

interface TimeSpanWrapperProps {
	expanded: boolean;
}

interface MeetingHeaderContentProps {
	expanded: boolean;
}

const ScrollWrapper = styled.div<{ disabled?: boolean }>`
	display: flex;
	flex-grow: 1;
	overflow: auto;
	filter: ${p => p.disabled ? "saturate(0.1)" : "none"};
	
	&, *:not(.no-pointer):not(.no-pointer *) {
		pointer-events: ${p => p.disabled ? "none" : "auto"} !important;
	}
`;

const Grid = styled.article<GridProps>`
	display: grid;
	grid-template-columns: max-content repeat(${p => p.dimensions.width}, ${p => p.fillWidth ? "1fr" : "180px"});
	grid-template-rows: auto repeat(${p => p.dimensions.height}, 30px);
	min-height: ${p => p.dimensions.height * 30}px;
	width: ${p => p.fillWidth ? "100%" : null};
	padding: 0 10px 10px 0;
	user-select: none;
	
	${p => {
		let xClasses = "";
		for (let i = 1, l = p.dimensions.width + 2; i < l; i++)
			xClasses += `.gx-${i} { grid-column-start: ${i} }`;

		let yClasses = "";
		for (let i = 1, l = p.dimensions.height + 2; i < l; i++)
			yClasses += `.gy-${i} { grid-row-start: ${i} }`;
		
		return xClasses + yClasses;
	}}

	${p => p.resizing ? `
		touch-action: none;
	
		* {
			touch-action: none !important;
			cursor: ns-resize !important;
		}
	` : null}

	.no-pointer {
		pointer-events: none;
	}
`;

const DayHeader = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: sticky;
	top: 0;
	text-align: center;
	padding: 10px 0;
	background: ${p => p.theme.background};
	font-weight: bold;
	line-height: 1;
	z-index: 1;
	
	&:before,
	&:after {
		content: "";
		position: absolute;
		left: 0;
	}

	&:before {
		bottom: 0;
		height: 10px;
		border-left: ${p => p.theme.boundaryBorder};
	}

	&:after {
		bottom: -1px;
		width: 100%;
		border-bottom: ${p => p.theme.boundaryBorder};
	}
`;

const SpreadButtonWrapper = styled.button`
	margin: -5px -5px -5px 3px;
	background: transparent;
	border: none;
	outline: none;
	padding: 3px 5px;
	font: inherit;
	color: inherit;
	cursor: pointer;
	
	svg {
		height: 1.5em;
		fill: currentColor;
		vertical-align: middle;
	}
`;

const SpreadButton = (props: SpreadButtonProps) => (
	<SpreadButtonWrapper onClick={props.onDaySelect}>
		<Icon name="spread" />
	</SpreadButtonWrapper>
);

const DayLabel = styled.span<DayLabelProps>`
	${p => p.today ? {
		background: p.theme.lightColor,
		color: p.theme.cardBackground,
		padding: "4px 10px",
		margin: "-2px 0",
		borderRadius: "20px"
	} : null}
`;

const TimeHeader = styled.div<TimeHeaderProps>`
	position: sticky;
	left: 0;
	padding: 0 15px 0 10px;
	background: ${p => p.theme.background};
	text-align: right;
	z-index: 11;
	
	${p => p.gridDimensions && p.y === p.gridDimensions.height + 1 ? {
		paddingBottom: "10px",
		marginBottom: "-10px"
	} : null}

	&:before,
	&:after {
		content: "";
		position: absolute;
		top: 0;
	}

	&:before {
		right: 0;
		width: 10px;
		border-top: ${p => p.theme.boundaryBorder};
	}

	&:after {
		right: -1px;
		height: 100%;
		border-right: ${p => p.theme.boundaryBorder};
		opacity: ${p => p.flush ? 0 : 1};
	}
`;

const TimeLabel = styled(Time)`
	display: inline-block;
	position: relative;
	transform: translateY(-50%);
	z-index: 10;
`;

const SplitTimeLabelBox = styled.div`
	display: grid;
	grid-template-columns: 1fr 14px 1fr;
	grid-auto-flow: column;
	gap: 8px;
	align-items: center;
	position: relative;
	height: 100%;
	transform: translateY(-50%);
`;

const SplitLabelSeparator = styled.div`
	position: relative;
	height: 100%;

	&:before,
	&:after {
		content: "";
		position: absolute;
		background: ${p => p.theme.boundaryBorderColor};
	}

	&:before {
		top: 50%;
		left: 0;
		width: 100%;
		height: 1px;
		transform: translateY(-50%);
	}

	&:after {
		top: 0;
		left: 50%;
		width: 1px;
		height: 100%;
		transform: translateX(-50%);
	}
`;

const TZSWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	// align-items: flex-end;
	grid-row-start: 1;
	grid-column-start: 1;
	position: sticky;
	top: 0;
	left: 10px;
	// padding-top: 12px;
	// margin: 0 15px -12px 10px;
	margin: 0 15px 0 10px;
	background: ${p => p.theme.background};
	box-shadow: 0 0 4px 3px ${p => p.theme.background};
	z-index: 20;
`;

const SplitTzBox = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 30px;
	width: 100%;
`;

const SplitTzContent = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const Cell = styled.div<CellProps>`
	border-top: ${p => p.theme.boundaryBorder};
	border-left: ${p => p.theme.boundaryBorder};
	cursor: ${p => p.disabled ? "not-allowed" : "pointer"};
	background: ${p => p.outOfScope ? p.theme.takenBackground : p.theme.backgroundLight};

	${p => p.gridDimensions && p.y === p.gridDimensions.height + 1 ? {
		paddingBottom: "10px",
		marginBottom: "-10px"
	} : null};

	${p => p.gridDimensions && p.x === p.gridDimensions.width + 1 ? {
		paddingRight: "10px",
		marginRight: "-10px"
	} : null};
`;

const CornerCell = styled.div`
	position: sticky;
	top: 0;
	left: 0;
	background: linear-gradient(to right, ${p => p.theme.background} 90%, ${p => transparentize(1, p.theme.background)}100%);
	z-index: 10;
`;

const CellOverlay = styled.div<CellOverlayProps>`
	display: grid;
	grid-area: ${p => p.y} / ${p => p.x} / span ${p => p.dimensions.height} / span ${p => p.dimensions.width};
	grid-template-columns: repeat(${p => p.dimensions.width}, 1fr);
	grid-template-rows: repeat(${p => p.dimensions.height}, 1fr);
`;

const TakenCell = styled.div.attrs<FixedCellProps>(p => ({
	style: {
		gridArea: `${p.y} / ${p.x} / span ${p.span}`
	}
}))<FixedCellProps>`
	position: relative;
	background: ${p => {
		if (p.unavailable)
			return p.theme.takenBackground;

		return `${p.theme.takenCellBackground} repeating-linear-gradient(
			120deg,
			${p.theme.takenCellForeground}, ${p.theme.takenCellForeground} 1px,
			transparent 1px, transparent 4px
		)`;
	}};
	margin: ${p => p.unavailable ? "0" : "4px 3px 3px 4px"};
	border-radius: ${p => p.theme.borderRadius};
	filter: ${p => p.disabled ? "saturate(0.4)" : null};
	cursor: not-allowed;
	pointer-events: auto;
`;

const CurrentMeeting = styled.div<FixedCellProps>`
	position: relative;
	display: grid;
	grid-template-rows: repeat(${p => p.span}, 1fr);
	grid-area: ${p => p.y} / ${p => p.x} / span ${p => p.span};
	margin: ${p => p.sinkIn ? "1px 0 0 1px" : "0 -1px -1px 0"};
	background: ${p => p.theme.popBackground};
	border-radius: ${p => p.theme.borderRadius};
	color: ${p => p.theme.popContrastColor};
	cursor: pointer;
	pointer-events: auto;
`;

const MeetingHeader = styled.div`
	position: relative;
`;

const AbsoluteMeetingHeaderBox = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	bottom: 100%;
	overflow: hidden;
	color: ${p => p.theme.color};
	text-shadow:
		// Inner circle
		1px -1px ${p => p.theme.cardBackground},
		1px 0 ${p => p.theme.cardBackground},
		1px 1px ${p => p.theme.cardBackground},
		0 1px ${p => p.theme.cardBackground},
		-1px 1px ${p => p.theme.cardBackground},
		-1px 0 ${p => p.theme.cardBackground},
		-1px -1px ${p => p.theme.cardBackground},
		0 -1px ${p => p.theme.cardBackground},
		// Partial outer circle
		2px -1px ${p => p.theme.cardBackground},
		2px 0 ${p => p.theme.cardBackground},
		2px 1px ${p => p.theme.cardBackground},
		-1px 2px ${p => p.theme.cardBackground},
		0 2px ${p => p.theme.cardBackground},
		1px 2px ${p => p.theme.cardBackground},
		-1px -2px ${p => p.theme.cardBackground},
		0 -2px ${p => p.theme.cardBackground},
		1px -2px ${p => p.theme.cardBackground};
`;

const AbsoluteMeetingHeaderTransformBox = styled.div`
	position: relative;
	transform: translateY(100%);
	opacity: 0;
	transition: transform 300ms, opacity 300ms;
`;

const RemoveButton = styled.button`
	position: relative;
	padding: 0;
	width: 22px;
	height: 22px;
	margin: -4px;
	border: none;
	outline: none;
	background: transparent;
	color: inherit;
	font: inherit;
	font-size: 130%;
	font-weight: bold;
	line-height: 22px;
	pointer-events: auto;
	cursor: pointer;
	z-index: 100;
	
	&:before,
	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: ${p => p.theme.borderRadius};
		transition: opacity 200ms;
		opacity: 0;
		z-index: -1;
	}
	
	&:before {
		background: currentColor;
	}
	
	&:after {
		border: 1px solid;
	}
	
	&:hover,
	&:focus {
		&:before,
		&:after {
			opacity: 0.2;
		}
	}
`;

const MeetingHeaderContent = styled.div<MeetingHeaderContentProps>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: ${p => p.expanded ? "7px 8px 15px" : "7px 8px"};
`;

const CellTitle = styled.div`
	padding: 0 8px;
	overflow: hidden;
`;

const CompactCellTitle = styled(CellTitle)`
	padding: 5px 8px;
`;

const MeetingExpander = styled.div`
	position: absolute;
	bottom: -8px;
	left: 0;
	width: 100%;
	height: 16px;
	cursor: ns-resize;
`;

const TimeSpanWrapper = styled.div<TimeSpanWrapperProps>`
	margin-bottom: ${p => p.expanded ? "-1em" : 0};
`;

const TimeSpanEntry = styled.div`
	line-height: 1;
`;

const TimeZoneLabel = styled.span`
	margin-left: 0.25em;
`;

const TimeSpan = (props: TimeSpanProps) => {
	const frame = props.frame,
		t = getLocalizedTime(props.event.start, frame.timeZone),
		t2 = frame.referenceTimeZone ?
			getLocalizedTime(props.event.start, frame.referenceTimeZone) :
			null;
	let referenceSpan = null,
		tTz = null,
		t2Tz = null;

	if (t2 && frame.timeZone !== frame.referenceTimeZone) {
		tTz = <TimeZoneLabel>{getTimeZoneShortName(frame.referenceTimeZone!)}</TimeZoneLabel>;
		t2Tz = <TimeZoneLabel>{getTimeZoneShortName(frame.timeZone)}</TimeZoneLabel>;

		if (props.expanded || props.event.duration > Math.round(frame.periodicity * 60)) {
			referenceSpan = (
				<TimeSpanEntry>
					<Time time={t2}/>
					<span> - </span>
					<Time time={t2} offset={props.event.duration * 60}/>
					{tTz}
				</TimeSpanEntry>
			);
		}
	}

	return (
		<TimeSpanWrapper expanded={Boolean(referenceSpan)}>
			<TimeSpanEntry>
				<Time time={t} />
				<span> - </span>
				<Time time={t} offset={props.event.duration * 60} />
				{t2Tz}
			</TimeSpanEntry>
			{referenceSpan}
		</TimeSpanWrapper>
	);
};

const SelectableMeeting = styled.div<SelectableCellProps>`
	position: relative;
	display: grid;
	grid-template-rows: min-content ${p => {
		const span = p.titled ?
			Math.max(p.span - 1, 1) :
			p.span;
		
		if (span <= 1)
			return "";
		
		return `repeat(${span - 1}, auto)`;
	}};
	grid-area: ${p => p.y} / ${p => p.x} / span ${p => p.span};
	margin: ${p => p.sinkIn ? "1px 0 0 1px" : "0 -1px -1px 0"};
	background: ${p => p.selected ? p.theme.popBackground : p.theme.cardBackground};
	border: ${p => p.selected ? "1px solid transparent" : `1px solid ${p.theme.color}`};
	border-radius: ${p => p.theme.borderRadius};
	color: ${p => p.selected ? p.theme.popContrastColor : p.theme.color};
	cursor: pointer;
	pointer-events: auto;
	
	&:hover .header-transform-box {
		transform: translateY(0);
		opacity: 1;
	}
`;

const SlotCursor = styled.div<FixedCellProps>`
	position: relative;
	grid-area: ${p => p.y} / ${p => p.x} / span ${p => p.span};
	margin-top: -2px;
	height: 2px;
	background: ${p => p.theme.popBackground};
	box-sizing: content-box;
	border-top: 1px solid ${p => p.theme.background};
	border-bottom: 1px solid ${p => p.theme.background};
	
	&:before,
	&:after {
		content: "";
		position: absolute;
		top: -5px;
		height: 12px;
		border-left: 2px solid ${p => p.theme.popBackground};
		border-radius: 1px;
		box-shadow: 0 0 0 1px ${p => p.theme.background};
	}

	&:before {
		left: 0;
	}
	
	&:after {
		right: -1px;
	}
`;

const eqEvent = (e: TimeEvent, e2: TimeEvent) => {
	if (e instanceof TakenTimeEvent && e2 instanceof TakenTimeEvent)
		return true;

	return e === e2;
};

function getLastMapValue<T>(map: Map<any, T>): T | null {
	const values = [] as T[];

	map.forEach(v => values.push(v));

	if (values.length)
		return values.pop()!;

	return null;
}

const getAvailabilityLookup = (
	availability: Availability | null,
	availabilityTimeZone: string,
	days: CalendarDay[]
): ((...args: DateValue[]) => boolean) => {
	if (!availability)
		return () => true;

	const blocks = new TimeBlocks(),
		startTime = days[0].startTime,
		endTime = days[days.length - 1].endTime,
		startYear = startTime.year,
		startMonth = startTime.month,
		startDay = startTime.day - 2,
		twoDays = 2 * 24 * 60 * 60 * 1000;
	let count = 0,
		offset = 0;

	while (true) {
		const timestamp = Date.UTC(
			startYear,
			startMonth,
			startDay + offset
		);

		offset++;

		if (timestamp > endTime.timestamp + twoDays)
			break;

		/*
		Application was not initially designed for weekends and would break existing config;
		re-write Sunday (0) to 6, otherwise - 1 to reflect array index
		*/
		const date = new Date(timestamp),
			day = date.getDay(),
			index = !day ? 6 : day - 1

		if (index < 0 || !availability[index])
			continue;

		for (const av of availability[index].ranges) {
			const sD = mkDate(
				date.getFullYear(),
				date.getMonth(),
				date.getDate(),
				~~(av.start / 100),
				av.start % 100,
				availabilityTimeZone
			);

			const eD = mkDate(
				date.getFullYear(),
				date.getMonth(),
				date.getDate(),
				~~(av.end / 100),
				av.end % 100,
				availabilityTimeZone
			);

			blocks.add({
				type: "taken",
				start: sD,
				end: eD,
				showInGui: true,
				eventType: "me",
				topic: ""
			});

			count++;
		}
	}

	if (!count)
		return () => true;

	return (...args: DateValue[]) => blocks.has(...args);
};

const CalendarView = (props: ThemedCalendarViewProps) => {
	const scrollRef = useRef(null);
	const eventRefs = useRef(new Map() as Map<TimeEvent, Element | null>);

	const [xFlush, setXFlush] = useState(true);
	const [refreshes, setRefreshes] = useState(0);
	const [resizeEvent, setResizeEvent] = useState(null as TimeEvent | null);

	const frame = resolveTimeFrame(props.frame),
		days = resolveCalendarDays(frame);

	const nowTime = getLocalizedTime(null, frame.timeZone),
		nowBlock = getUtcBlock(Date.now()) + 1,
		width = days.length,
		height = days[0].endBlock - days[0].startBlock,
		dimensions = {
			width,
			height
		};
	const eventConfig = useMemo(
		() => ({
			minDuration: typeof props.eventConfig?.minDuration == "number" ?
				props.eventConfig.minDuration :
				Math.round(frame.periodicity * 60),
			maxDuration: typeof props.eventConfig?.maxDuration == "number" ?
				props.eventConfig.maxDuration :
				Infinity
		} as AugmentedEventConfig),
		[props.eventConfig]
	);

	const availabilityLookup = useMemo(
		() => getAvailabilityLookup(
			props.availability || null,
			props.availabilityTimeZone || frame.timeZone,
			days
		),
		[props.availability, props.availabilityTimeZone]
	);

	const [
		blocks,
		selection
	] = useMemo(
		() => {
			const b = new TimeBlocks(),
				s = [] as TimeEvent[];
			for (const event of props.events || []) {
				b.add(event);
			}

			for (const sel of props.selection || [])
				s.push(b.add(sel));

			return [b, s];
		},
		[props.events, props.selection, props.reload]
	);

	useEffect(
		() => {
			const getSlot = () => {
				const period = Math.round(frame.periodicity * 60) * 60 * 1000;
				return Math.floor(Date.now() / period);
			};

			let slot = getSlot();

			const id = window.setInterval(
				() => {
					const s = getSlot();
					if (s === slot)
						return;

					setRefreshes(rf => rf + 1);
					slot = s;
				},
				1000
			);

			return () => window.clearInterval(id);
		},
		[]
	);

	useEffect(() => {
		setTimeout(() => {
			const content = getLastMapValue(eventRefs.current);

			if (!content || props.autoScroll === false || matchMedia(props.theme.mobileMedia).matches)
				return;

			(content as Element).scrollIntoView({
				block: "nearest",
				inline: "nearest",
				behavior: "smooth"
			});
		}, 250);

		setTimeout(updateScroll, 400);
	}, [eventRefs.current.size, props.theme.mobileMedia]);


	useEffect(() => {
		if(!props.autoScroll) return;

		setTimeout(() => {
			// @ts-ignore
			const calendarContainer = document.querySelector(".calendar-view") as HTMLElement;
			const content = document.querySelector(".gy-32.gx-1") as HTMLElement;

			if (!content)
				return;

			calendarContainer.scrollTo({
				top: content.offsetTop,
				left: 0,
				behavior: "smooth"
			});
		}, 50);

		setTimeout(updateScroll, 400);
	}, [eventRefs.current.size, props.theme.mobileMedia]);

	const triggerRefresh = () => {
		requestAnimationFrame(
			() => setRefreshes(refreshes + 1)
		);
	};

	const updateScroll = () => {
		const elem = scrollRef.current! as Element;

		if (elem)
			setXFlush(!elem.scrollLeft);
	};

	const triggerEntrySelect = (time: ITime, day: CalendarDay) => {
		if (typeof props.onSelect != "function")
			return;

		const event = TimeEvent.resolve({
			type: "entry",
			start: time.timestamp,
			duration: eventConfig.minDuration,
			showInGui: true,
			eventType: "me",
			topic: ""
		});

		props.onSelect({
			event,
			day
		});

		triggerRefresh();
	};

	const triggerSelectableSelect = (event: TimeEvent, day: CalendarDay) => {
		if (typeof props.onSelect != "function")
			return;

		props.onSelect({
			event,
			day
		});

		triggerRefresh();
	};

	const startResize = (event: TimeEvent) => {
		setResizeEvent(event);
	};

	const resize = (evt: any) => {
		if (resizeEvent === null)
			return;

		const header = eventRefs.current.get(resizeEvent);
		if (!header)
			return;

		const bcr = (header as Element).getBoundingClientRect(),
			point = evt.targetTouches ?
				evt.targetTouches[0] :
				evt;

		const currentSteps = Math.round(resizeEvent.duration / Math.round(frame.periodicity * 60)),
			rawSteps = Math.round((point.clientY - bcr.top) / (bcr.height / currentSteps)),
			maxSteps = Math.round((eventConfig.maxDuration || Infinity) / frame.periodicity),
			steps = Math.min(Math.max(rawSteps, 1), maxSteps);
		let expandedSteps = 0;

		for (let i = 0; i < steps; i++) {
			const evt = blocks.get(resizeEvent.startBlock + i),
				unavailable = !isAvailable(resizeEvent.startBlock + i);

			if (unavailable || (evt && evt !== resizeEvent))
				break;

			expandedSteps++;
		}

		const duration = Math.max(
			Math.min(
				Math.round(expandedSteps * frame.periodicity * 60),
				eventConfig.maxDuration
			),
			eventConfig.minDuration
		);

		if (props.onUpdate && duration !== resizeEvent.duration) {
			const newEvent = TimeEvent.resolve({
				type: "entry",
				start: resizeEvent.start,
				showInGui: true,
				duration,
				eventType: "me",
				topic: ""
			});

			setResizeEvent(newEvent);

			props.onUpdate({
				event: newEvent,
				oldEvent: resizeEvent,
				day: days[0]
			});
		}
	}

	const endResize = () => {
		if (resizeEvent) {
			setResizeEvent(null);
			triggerRefresh();
		}
	};

	const isAvailable = (...args: DateValue[]): boolean => {
		const b = args.length === 1 && typeof args[0] === "number" && args[0] < 1e7 ?
			args[0] :
			getUtcBlock(...args);
		return b >= nowBlock + props.bookingBuffer && availabilityLookup(b);
	};

	const canSelect = (args: CellArgs): boolean => {
		if (!props.selectable || blocks.has(args.block) || !isAvailable(args.block))
			return false;

		if (typeof props.canSelect == "function")
			return props.canSelect(args);

		for (let i = 1; i < args.span; i++) {
			if (blocks.has(args.block + i) || !isAvailable(args.block + i))
				return false;
		}

		return true;
	};

	const isInScope = (args: CellArgs): boolean => {
		if (!isAvailable(args.block))
			return false;

		if (typeof props.isInScope == "function")
			return props.isInScope(args);
		return true;
	};

	const genCells = (
		callback: (cx: number, cy: number) => JSX.Element
	) => {
		const cls = [] as JSX.Element[];

		for (let i = -1; i < height; i++) {
			for (let j = -1; j < width; j++)
				cls.push(callback(j, i));
		}

		return cls;
	};

	// Generate grid (cells and headers)
	const cellMemoBase = [
		props.events,
		frame.extent,
		frame.timeZone,
		frame.referenceTimeZone,
		xFlush,
		nowBlock,
		availabilityLookup
	];


	const cellsMemo = resizeEvent ?
		[...cellMemoBase, refreshes] :
		[...cellMemoBase, props.selection];

	const cells = useMemo(
		() => genCells((cx, cy) => {
			const x = cx + 2,
				y = cy + 2,
				key = `grid-${x}:${y}`,
				gridClass = `gx-${x} gy-${y}`;

			if (cx === -1 && cy === -1) {
				return (
					<CornerCell
						key={key}
						className={gridClass}
					/>
				);
			}

			const t = getLocalizedTime(
				days[Math.max(cx, 0)].startTime.timestamp +
					(Math.round(frame.periodicity * 60) * 60 * 1000) *
					Math.max(cy, 0),
				frame.timeZone
			);

			if (cy === -1) {
				const labelContent = props.daysOnly ?
					DAYS[t.dayIndex] :
					<>{DAYS_SHORT[t.dayIndex]} {t.day} {MONTHS_SHORT[t.month]}</>

				const selectDay = () => {
					const payload = {
						events: props.events || [],
						selection,
						day: days[cx]
					} as DaySelectEvent;

					props.onDaySelect!(payload);
				};

				const selectButton = props.onDaySelect ?
					<SpreadButton onDaySelect={selectDay} /> :
					null;

				return (
					<DayHeader
						key={key}
						className={gridClass}
					>
						<DayLabel today={t.dayCode === nowTime.dayCode}>
							{labelContent}
						</DayLabel>
						{selectButton}
					</DayHeader>
				);
			}

			if (cx === -1) {
				const displayLabel = y !== 2 && t.minute % Math.round(DISPLAY_PERIODICITY * 60) === 0,
					displaySplit = frame.timeZone &&
						frame.referenceTimeZone &&
						frame.timeZone !== frame.referenceTimeZone;
				let headerContent = null;

				if (displaySplit) {
					const d = new Date(),
						dv = [
							d.getFullYear(),
							d.getMonth(),
							d.getDate(),
							t.hour,
							t.minute
						],
						time = getLocalizedTime(
							mkDate(...dv, frame.timeZone),
							frame.timeZone
						),
						refTime = getLocalizedTime(
							mkDate(...dv, frame.referenceTimeZone),
							frame.referenceTimeZone
						),
						offset = (time.offset - refTime.offset) * 60;

					const leftTime = displayLabel ?
							<Time time={t} /> :
							<div />,
						rightTime = displayLabel ?
							<Time time={time} offset={offset} /> :
							<div />

					headerContent = (
						<SplitTimeLabelBox>
							{leftTime}
							<SplitLabelSeparator />
							{rightTime}
						</SplitTimeLabelBox>
					);
				} else if (displayLabel)
					headerContent = <TimeLabel time={t} />;

				return (
					<TimeHeader
						key={key}
						x={x}
						y={y}
						flush={xFlush}
						gridDimensions={dimensions}
						className={gridClass}
					>
						{headerContent}
					</TimeHeader>
				);
			}

			const args = {
				time: t,
				day: days[cx],
				block: getUtcBlock(t.timestamp),
				blocks,
				span: eventConfig.minDuration / Math.round(frame.periodicity * 60),
				duration: eventConfig.minDuration,
				isAvailable
			} as CellArgs;

			const canSel = canSelect(args),
				outOfScope = !isInScope(args);

			return (
				<Cell
					className={gridClass}
					key={key}
					x={x}
					y={y}
					disabled={!canSel || outOfScope}
					outOfScope={outOfScope}
					gridDimensions={dimensions}
					onClick={() => canSel && !outOfScope && triggerEntrySelect(t, args.day)}
				/>
			);
		}),
		cellsMemo
	);

	// Validate that selected events fall within acceptable bounds,
	// else dispatch removal event for all applicable events
	for (const sel of selection) {
		if (sel.startBlock < nowBlock + props.bookingBuffer && typeof props.onRemove == "function") {
			props.onRemove({
				event: sel,
				day: days.find(day => sel.startBlock >= day.startBlock)!
			});
		}
	}

	// Generate content on top of grid
	const areas = [] as Area[],
		events = [] as JSX.Element[]
	let slotCursor = null;

	// Calculate areas
	for (let i = 0; i < width; i++) {
		const day = days[i];
		let area = null as Area | null;

		for (let j = 0; j < height; j++) {
			const block = day.startBlock + j,
				event = blocks.get(block),
				unavailable = !isAvailable(block);

			if (block === nowBlock && j > 0) {
				slotCursor = (
					<SlotCursor
						x={i + 1}
						y={j + 1}
						span={1}
					/>
				);
			}

			if (area && (unavailable || !event || !eqEvent(event, area.event))) {
				areas.push(area);
				area = null;
			}

			if (!event || unavailable)
				continue;

			// Ignore any non-taken event that is out of bounds
			if (!(event instanceof TakenTimeEvent) && event.startBlock < nowBlock + props.bookingBuffer)
				continue;


			if (!area) {
				area = {
					x: i + 1,
					y: j + 1,
					span: 0,
					event,
					day
				};
			}

			area.span++;
		}

		if (area)
			areas.push(area);
	}

	// Apply areas
	for (const area of areas) {
		const hasExtendedDuration = area.event.duration > Math.round(frame.periodicity * 60),
			hasTitle = Boolean(area.event.name),
			canExpand = frame.timeZone !== frame.referenceTimeZone,
			expanded = canExpand && hasExtendedDuration;

		switch (area.event.constructor) {
			case TakenTimeEvent:
				events.push(
					<TakenCell
						key={`${area.x}-${area.y}`}
						x={area.x}
						y={area.y}
						span={area.span}
					/>
				);
				break;

			case EntryTimeEvent: {
				let right = <span>{area.event.duration}m</span>,
					resizer = null;

				if (props.onRemove && props.removable) {
					const handleRemove = () => {
						props.onRemove!({
							event: area.event,
							day: area.day
						});
					};

					right = (
						<RemoveButton onClick={handleRemove}>
							&times;
						</RemoveButton>
					);
				}

				if (props.resizable) {
					resizer = (
						<MeetingExpander
							onMouseDown={() => startResize(area.event)}
							onTouchStart={() => startResize(area.event)}
						/>
					);
				}

				events.push(
					<CurrentMeeting
						className="selected-slot"
						ref={el => eventRefs.current.set(area.event, el)}
						key={`${area.x}-${area.y}`}
						x={area.x}
						y={area.y}
						span={area.span}
						sinkIn={props.sinkIn}
					>
						<MeetingHeader>
							<MeetingHeaderContent expanded={expanded}>
								<TimeSpan
									event={area.event}
									frame={frame}
								/>
								{right}
							</MeetingHeaderContent>
						</MeetingHeader>
						{resizer}
					</CurrentMeeting>
				);
				break;
			}

			case SelectableTimeEvent: {
				const title = hasExtendedDuration ?
					<CellTitle>{area.event.name}</CellTitle> :
					<CompactCellTitle>{area.event.name}</CompactCellTitle>;

				let header = (
					<MeetingHeader>
						<MeetingHeaderContent
							expanded={canExpand}
						>
							<TimeSpan
								event={area.event}
								frame={frame}
								expanded={!hasExtendedDuration && hasTitle}
							/>
						</MeetingHeaderContent>
					</MeetingHeader>
				);

				if (!hasExtendedDuration) {
					// if its less than 60 minutes, we dont have a ton of vertical height. move some info to a hover tip.
					header = (
						<AbsoluteMeetingHeaderBox className="absolute-header-box no-pointer">
							<AbsoluteMeetingHeaderTransformBox className="header-transform-box">
								{header}
							</AbsoluteMeetingHeaderTransformBox>
						</AbsoluteMeetingHeaderBox>
					);
				}

				/* Controls the white selectable time boxes */
				if (area.event.showInGui) {
					events.push(
						<SelectableMeeting
							key={`${area.x}-${area.y}`}
							x={area.x}
							y={area.y}
							span={area.span}
							sinkIn={props.sinkIn}
							titled={Boolean(area.event.name)}
							selected={selection.includes(area.event)}
							onClick={() => triggerSelectableSelect(area.event, area.day)}

						>
							{header}
							{title}
						</SelectableMeeting>
					);
				}
				break;
			}
		}
	}

	let tzSelectors;

	if (!frame.timeZone || !frame.referenceTimeZone || frame.timeZone === frame.referenceTimeZone) {
		tzSelectors = (
			<TzSelect
				compact
				name="tz-select-self"
				readOnly={props.editableTimeZones}
				value={frame.presentationTimeZone || frame.timeZone || ""}
				onChange={evt => props.onTimeZoneSelect?.(evt)}
			/>
		);
	} else {
		tzSelectors = (
			<SplitTzBox>
				<SplitTzContent>
					<TzSelect
						compact
						name="tz-select-self"
						readOnly={props.editableTimeZones}
						value={frame.presentationTimeZone || frame.timeZone}
						onChange={evt => props.onTimeZoneSelect && props.onTimeZoneSelect(evt)}
					/>
				</SplitTzContent>
				<SplitTzContent>
					<TzSelect
						alt
						compact
						name="tz-select-team"
						readOnly={props.editableTimeZones}
						value={frame.referenceTimeZone}
						onChange={evt => props.onReferenceTimeZoneSelect && props.onReferenceTimeZoneSelect(evt)}
					/>
				</SplitTzContent>
			</SplitTzBox>
		);
	}

	return (
		<ScrollWrapper
			className="calendar-view"
			ref={scrollRef}
			disabled={props.disabled}
			onWheelCapture={updateScroll}
			onMouseMove={resize}
			onTouchMove={resize}
			onMouseUp={endResize}
			onMouseLeave={endResize}
			onTouchEnd={endResize}
		>
			<Grid
				dimensions={dimensions}
				resizing={resizeEvent !== null}
				fillWidth={props.fill}
			>
				{cells}
				<CellOverlay
					className="cell-overlay no-pointer"
					x={2}
					y={2}
					dimensions={dimensions}
				>
					{events}
					{slotCursor}
				</CellOverlay>
				<TZSWrapper>
					{tzSelectors}
				</TZSWrapper>
			</Grid>
		</ScrollWrapper>
	);
};

export default withTheme(CalendarView);
