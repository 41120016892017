import styled from "styled-components";
import {transparentize} from "polished";
import Icon from "./icon";

interface ModalProps {
	title: string;
	open: boolean;
	onCloseRequest?: () => void;
	children?: any;
	className?: string;
}

const ModalContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
`;

const ModalBackground = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: ${p => transparentize(0.5, p.theme.color)};
`;

const ModalBox = styled.article`
	display: flex;
	flex-direction: column;
	min-width: 200px;
	max-width: 90%;
	min-height: 100px;
	max-height: 80%;
	padding: 10px 15px 15px;
	background: ${p => p.theme.cardBackground};
	border-radius: ${p => p.theme.borderRadius};
	overflow: hidden;
	z-index: 1;
`;

const ModalHead = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-shrink: 0;
	padding-bottom: 10px;
	border-bottom: 1px solid ${p => p.theme.separatorColor};
`;

const ModalTitle = styled.h1`
	font-size: 110%;
	margin: 0;
`;

const ModalClose = styled.button`
	width: 30px;
	height: 30px;
	margin-right: -5px;
	background: transparent;
	border: none;
	cursor: pointer;
`;

const ModalContent = styled.div`
	display: flex;
	flex-grow: 1;
	overflow: auto;
	padding: 15px 5px 5px;
	margin: 0 -5px -5px;
`;

const Modal = (props: ModalProps) => {
	if (!props.open)
		return <></>;

	const requestClose = () => {
		if (typeof props.onCloseRequest == "function")
			props.onCloseRequest();
	};

	return (
		<ModalContainer>
			<ModalBackground onClick={requestClose} />
			<ModalBox className={props.className}>
				<ModalHead>
					<ModalTitle>{props.title}</ModalTitle>
					<ModalClose onClick={requestClose}>
						<Icon name="cross" />
					</ModalClose>
				</ModalHead>
				<ModalContent>
					{props.children}
				</ModalContent>
			</ModalBox>
		</ModalContainer>
	);
};

export default Modal;
