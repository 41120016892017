import { useOktaAuth } from "@okta/okta-react";
import styled from "styled-components";

import Card from "../components/card";
import Button from "../components/button";
import Centered from "../components/centered";

const CardContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	
	h1 {
		position: relative;
		font-size: 110%;
		margin: 0 0 0.8em;
		padding: 0 36px;
		text-transform: uppercase;
		
		&:before,
		&:after {
			content: "";
			position: absolute;
			top: 50%;
			width: 30px;
			transform: translateY(-50%);
			border-top: 1px solid;
			opacity: 0.3;
		}
		
		&:before { left: 0 }
		&:after { right: 0 }
	}
	
	article {
		max-width: 400px;
	}
`;

const LoginPrompt = styled.span`
	margin: 0 15px 0 5px;
`;

const Login = () => {
	const { oktaAuth } = useOktaAuth();

	const login = async () => {
		try {
			await oktaAuth.signInWithRedirect();
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<Centered>
			<CardContainer>
				<h1>Employee Log in</h1>
				<Card>
					<LoginPrompt>Log in with Okta</LoginPrompt>
					<Button
						square
						onClick={login}
					>
						Log in
					</Button>
				</Card>
			</CardContainer>
		</Centered>
	);
};

export default Login;
