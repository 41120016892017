import styled from "styled-components"

import LoadingIcon from "./loading-icon";

interface LoadingBoxProps {
	children?: any;
}

const LoadingBoxWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const LoadingContent = styled.p`
	margin: 2em 0 0;
`;

const LoadingBox = (props: LoadingBoxProps) => {
	const content = props.children ?
		<LoadingContent>{props.children}</LoadingContent> :
		null;

	return (
		<LoadingBoxWrapper>
			<LoadingIcon />
			{content}
		</LoadingBoxWrapper>
	);
};

export default LoadingBox;
