import {
	useState,
	useEffect
} from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { request } from "../util/async";

import Icon from "../components/icon";
import Centered from "../components/centered";
import ErrorBox from "../components/error-box";
import LoadingBox from "../components/loading-box";

const ConfirmationMessage = styled.div`
	display: flex;
	align-items: center;

	svg {
		height: 60px;
		vertical-align: middle;
		stroke: none;
		fill: ${p => p.theme.popBackground};
		margin-right: 20px;
		opacity: 0.7;
	}

	h1 {
		font-size: 170%;
		margin: 0 0 0.5em 0;
	}

	p {
		max-width: 400px;
		margin: 0;
	}

	@media ${p => p.theme.mobileMedia} {
		flex-direction: column;
		text-align: center;

		svg {
			height: 80px;
			margin: 0 0 25px;
		}
	}
`;

const Meeting = () => {
	const params = useParams();

	const [clientErrorMessage, setClientErrorMessage] = useState(null as string | null);
	const [success, setSuccess] = useState(false);

	useEffect(
		() => {
			const confirm = async () => {
				const res = await request(`book/${(params as any).link}/confirm`);

				if (res.success)
					setSuccess(true);
				else
					setClientErrorMessage(res.errorMessage);
			};

			confirm();
		},
		[]
	);

	let content;

	if (success) {
		content = (
			<ConfirmationMessage>
				<div>
					<Icon name="success-badge" />
				</div>
				<div>
					<h1>Meeting confirmed</h1>
					<p>Your meeting has been confirmed! You may now close this window</p>
				</div>
			</ConfirmationMessage>
		);
	} else if (clientErrorMessage)
		content = <ErrorBox>{clientErrorMessage}</ErrorBox>
	else
		content = <LoadingBox>Confirming meeting</LoadingBox>;

	return (
		<Centered>
			{content}
		</Centered>
	);
};

export default Meeting;
