import { useState } from "react";
import styled from "styled-components";

import wrap from "./core/wrap";

import DropBox, {
	DropMeta,
	DropComponentProps
} from "./core/drop-box";

import { WrappedInputProps } from "../../types/forms";
import useSelectable from "../../hooks/use-selectable";

interface TimeProps {

}

interface HeadWrapperProps extends WrappedInputProps<string, TimeProps> {
	dropMeta: DropMeta;
	type: string;
}

const HeadWrapper = styled.button<HeadWrapperProps>`
	${p => p.baseStyle};
	border-radius: ${p => p.dropMeta.headBorderRadius.composite};
`;

const Head = (props: DropComponentProps<boolean>) => (
	<HeadWrapper
		{...props.inputProps as any}
		dropMeta={props.dropMeta}
		type="button"
	>
		TEST
	</HeadWrapper>
);

const BodyWrapper = styled.div`
	height: 150px;
	background: red;
`;

const Body = () => (
	<BodyWrapper>
		QUITE LONG TEXT RIGHT HERE
		<button>AHA</button>
	</BodyWrapper>
);

const OPTIONS = ["A", "B", "C", "D"],
	SELECTION = [] as any[];

const TimeComponent = (props: WrappedInputProps<boolean, TimeProps>) => {
	const [expanded, setExpanded] = useState(false);

	const selectable = useSelectable({
		options: OPTIONS,
		selection: SELECTION,
		autoSet: true
	});

	return (
		<DropBox
			inputProps={props}
			expanded={expanded}
			head={Head}
			body={Body}
			margin={20}
			borderRadius={3}
			onFocus={() => setExpanded(true)}
			onBlur={() => setExpanded(false)}
		/>
	);
};

const Time = wrap({
	observe: [],
	component: TimeComponent
});

export default Time;
