import styled from "styled-components";

import wrap from "./core/wrap";

import { WrappedInputProps } from "../../types/forms";

interface TextProps {
	type?: string;
	pattern?: string;
	placeholder?: string;
	min?: number;
}

const TextWrapper = styled.input<WrappedInputProps<string>>`
	${p => p.baseStyle};
`;

const Text = wrap({
	observe: ["change"],
	component: (props: WrappedInputProps<string, TextProps>) => <TextWrapper {...props} />
});

export default Text;
