import {
	useRef,
	useState
} from "react";
import { transparentize } from "polished";
import styled from "styled-components";

import { resolveFormEvent } from "./wrap";

import {
	InputComponent,
	WrappedInputProps
} from "../../../types/forms";
import Icon from "../../icon";

interface CopyProps<V, P, H> {
	input: InputComponent<V, P, H>;
	inputProps: WrappedInputProps<V, P, H> & Partial<WrappedInputProps<V, P, "onCopy">>;
}

interface RefProps {
	ref: any;
}

interface CopyAreaProps {
	tabIndex: number;
}

interface CopyButtonProps {
	disabled?: boolean;
	accented?: boolean;
}

interface SuccessOverlayProps {
	active: boolean;
}

const CopyWrapper = styled.div`
	position: relative;
	
	.copy-input {
		width: 100%;
	}
`;

const CopyArea = styled.textarea<CopyAreaProps & RefProps>`
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	opacity: 0;
	pointer-events: none;
	z-index: -1000;
`;

const CopyButton = styled.button<CopyButtonProps & RefProps>`
	position: absolute;
	top: 5px;
	bottom: 5px;
	right: 5px;
	padding: 4px;
	width: 25px;
	background: ${p => p.theme.background};
	border: ${p => p.theme.boundaryBorder};
	border-radius: ${p => p.theme.borderRadius};
	outline: none;
	cursor: ${p => p.disabled ? "default" : "pointer"};
	z-index: 10;
	
	&:before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		right: 100%;
		width: 40px;
		margin-right: 1px;
		background: ${p => {
			const background = p.accented ?
				p.theme.cardBackground :
				p.theme.background;
		
			return `linear-gradient(to right, ${transparentize(1, background)} 0%, ${background} 90%)`;
		}};
		pointer-events: none;
		z-index: -1;
	}
	
	&:focus {
		border-color: ${p => p.theme.popBackground};
		background: ${p => p.theme.popBackground};
		color: ${p => p.theme.popContrastColor};
	}

	svg {
		height: 100%;
		fill: currentColor;
	}
`;

const SuccessOverlay = styled.div<SuccessOverlayProps>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: ${p => p.theme.borderRadius};
	background: ${p => p.theme.popBackground};
	pointer-events: none;
	transition: opacity 100ms;
	opacity: ${p => p.active ? 0.2 : 0};
	z-index: 11;
`;

function CopyBox<V, P, H>(props: CopyProps<V, P, H>) {
	const textAreaRef = useRef();
	const buttonRef = useRef();

	const [active, setActive] = useState(false);

	const {
		input: Input,
		inputProps
	} = props;

	const doCopy = () => {
		const area = textAreaRef.current as any,
			button = buttonRef.current as any;

		if (!area || !button)
			return;

		if (typeof inputProps.onCopy == "function") {
			const evt = resolveFormEvent(
				inputProps.name || "",
				{
					type: "copy",
					value: inputProps.value
				}
			);

			inputProps.onCopy(evt);
		} else {
			area.value = inputProps.value;
			area.select();
			document.execCommand("copy");
		}

		setActive(true);
		setTimeout(() => setActive(false), 100);
		button.focus();
	};

	return (
		<CopyWrapper className="copy-box">
			<CopyArea
				ref={textAreaRef}
				tabIndex={-1}
			/>
			<Input
				className="copy-input"
				{...inputProps}
			/>
			<CopyButton
				ref={buttonRef}
				disabled={inputProps.disabled}
				accented={inputProps.accented}
				onClick={doCopy}
				type="button"
			>
				<Icon name="copy" />
			</CopyButton>
			<SuccessOverlay active={active} />
		</CopyWrapper>
	);
}

export default CopyBox;
