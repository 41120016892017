import { useMemo } from "react";
import styled, { css } from "styled-components";
import { transparentize } from "polished";

import useSelectable from "../../hooks/use-selectable";

import wrap from "./core/wrap";

import {
	Option,
	CompiledOption,
	WrappedInputProps
} from "../../types/forms";

interface LinearSelectProps<V> {
	options: V[] | Option<V>[];
	hash?: (option: CompiledOption<V>) => string;
}

interface ItemTargetProps {
	selected: boolean;
}

type WrappedLinearSelectProps<V> = WrappedInputProps<V, LinearSelectProps<V>>;

const LinearSelectWrapper = styled.div<WrappedLinearSelectProps<any>>`
	display: flex;
	${p => p.baseStyle};
	padding: 3px;
`;

const Track = styled.div`
	display: flex;
	height: 100%;
	background: ${p => p.theme.background};
	border-radius: ${p => p.theme.borderRadius};
`;

const ITEM_BASE_STYLE = css`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 3px 10px;
	background: transparent;
	border: none;
	border-radius: ${p => p.theme.borderRadius};
	outline: none;
	font: inherit;
	color: inherit;
`;

const ItemTarget = styled.button<ItemTargetProps>`
	${ITEM_BASE_STYLE};
	background: ${p => p.selected ? p.theme.popBackground : "transparent"};
	color: ${p => p.selected ? p.theme.popContrastColor : "inherit"};
	cursor: pointer;

	&:focus {
		box-shadow: 0 0 0 1px ${p => p.theme.popBackground}, 0 0 0 3px ${p => transparentize(0.5, p.theme.popBackground)};
	}
`;

function LinearSelectComponent<V>(props: WrappedLinearSelectProps<V>) {
	const selection = useMemo(
		() => [props.value],
		[props.value]
	) as V[];

	const selectable = useSelectable({
		options: props.options,
		selection,
		hash: props.hash
	});

	const handleChange = (value: V) => props.dispatchBubble("change", value);

	const itemTargets = selectable.options.map((option, idx) => (
		<ItemTarget
			key={idx}
			selected={option === selectable.selection[0]}
			onClick={() => handleChange(option.value)}
		>
			{option.label}
		</ItemTarget>
	));

	return (
		<LinearSelectWrapper {...props}>
			<Track>
				{itemTargets}
			</Track>
		</LinearSelectWrapper>
	);
}

const LinearSelect = wrap({
	observe: ["change"],
	component: LinearSelectComponent
});

export default LinearSelect;
