import lightTheme from "./light";

const availabilityTheme = {
	...lightTheme,
	// Backgrounds
	background: "white",
	backgroundLight: "white",
	popBackground: "#00c0e8"
};

export default availabilityTheme;
