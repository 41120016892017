import styled from "styled-components";

import { IconName } from "../types/icon";

interface IconProps {
	name: IconName;
	className?: "string";
}

const PATHS: Record<IconName, JSX.Element> = {
	"arrow-right": (
		<path d="M45.93,17.82a3,3,0,0,1,4.24.11l19,20a3,3,0,0,1,0,4.14l-19,20a3,3,0,0,1-4.35-4.14L60,43H13a3,3,0,0,1,0-6H60L45.82,22.07A3,3,0,0,1,45.93,17.82Z" />
	),
	calendar: (
		<path d="M65,16H60V12a2,2,0,0,0-2-2H52a2,2,0,0,0-2,2v4H30V12a2,2,0,0,0-2-2H22a2,2,0,0,0-2,2v4H15a5,5,0,0,0-5,5V65a5,5,0,0,0,5,5H65a5,5,0,0,0,5-5V21A5,5,0,0,0,65,16ZM47,33V47H33V33ZM17,34.5A1.5,1.5,0,0,1,18.5,33H31V47H17ZM18.5,63A1.5,1.5,0,0,1,17,61.5V49H31V63ZM33,63V49H47V63Zm30-1.5A1.5,1.5,0,0,1,61.5,63H49V49H63ZM63,47H49V33H61.5A1.5,1.5,0,0,1,63,34.5Z" />
	),
	camera: (
		<>
			<path d="M10,26a3,3,0,0,1,3-3H47a3,3,0,0,1,3,3V54a3,3,0,0,1-3,3H13a3,3,0,0,1-3-3Z" />
			<path d="M70,53.52v-27a1,1,0,0,0-1.37-.93L56.26,30.5A2,2,0,0,0,55,32.35v15.3a2,2,0,0,0,1.26,1.85l12.37,5A1,1,0,0,0,70,53.52Z" />
		</>
	),
	check: (
		<path d="M65.13,16.48A3,3,0,0,1,66,20.63l-27.5,42.5a3,3,0,0,1-4.11.91l-20-12.5a3,3,0,1,1,3.18-5.08l17.5,10.93L61,17.37A3,3,0,0,1,65.13,16.48Z" />
	),
	clock: (
		<>
			<path d="M40,70A30,30,0,1,0,10,40,30,30,0,0,0,40,70Zm0-6A24,24,0,1,0,16,40,24,24,0,0,0,40,64Z" />
			<path d="M58.24,32.44a2,2,0,0,1-.77,2.72l-16.26,9a2.48,2.48,0,0,1-1.42.3,2.46,2.46,0,0,1-1.58-.4l-8.92-5.9a2,2,0,0,1-.57-2.77l.56-.84A2,2,0,0,1,32.05,34l7.84,5.18L55,30.79a2,2,0,0,1,2.72.77Z" />
		</>
	),
	controls: (
		<>
			<path d="M16,24a1,1,0,0,1,1-1H37a1,1,0,0,1,1,1v8a1,1,0,0,1-1,1H17a1,1,0,0,1-1-1Z" />
			<path d="M42,48a1,1,0,0,1,1-1H63a1,1,0,0,1,1,1v8a1,1,0,0,1-1,1H43a1,1,0,0,1-1-1Z" />
			<path d="M25,10a1,1,0,0,0-1,1v9h6V11a1,1,0,0,0-1-1Zm5,26H24V69a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1Z" />
			<path d="M51,10a1,1,0,0,0-1,1V44h6V11a1,1,0,0,0-1-1Zm5,50H50v9a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1Z" />
		</>
	),
	copy: (
		<>
			<path d="M33,20a3,3,0,0,0-3,3V67a3,3,0,0,0,3,3H67a3,3,0,0,0,3-3V23a3,3,0,0,0-3-3Zm3.5,5A1.5,1.5,0,0,0,35,26.5v37A1.5,1.5,0,0,0,36.5,65h27A1.5,1.5,0,0,0,65,63.5v-37A1.5,1.5,0,0,0,63.5,25Z"/>
			<path d="M10,13a3,3,0,0,1,3-3H47a3,3,0,0,1,3,3v3H44.91a1.5,1.5,0,0,0-1.41-1h-27A1.5,1.5,0,0,0,15,16.5v37A1.5,1.5,0,0,0,16.5,55H26v5H13a3,3,0,0,1-3-3Z"/>
		</>
	),
	cross: (
		<path d="M10.88,10.88a3,3,0,0,1,4.24,0L40,35.76,64.88,10.88a3,3,0,0,1,4.24,4.24L44.24,40,69.12,64.88a3,3,0,0,1-4.24,4.24L40,44.24,15.12,69.12a3,3,0,0,1-4.24-4.24L35.76,40,10.88,15.12A3,3,0,0,1,10.88,10.88Z" />
	),
	drop: (
		<path d="M10.72,29.05A3,3,0,0,1,15,28.72L40,50.06l25-21.34A3,3,0,1,1,69,33.28l-27,23a3,3,0,0,1-3.9,0l-27-23A3,3,0,0,1,10.72,29.05Z" />
	),
	fast: (
		<>
			<path d="M22.42,56.34A24,24,0,0,1,40,16c.51,0,1,0,1.51.05l3.42-5.65A30.75,30.75,0,0,0,40,10,30,30,0,0,0,19.29,61.71ZM30.33,62l-5.06,4.17A30,30,0,0,0,64.58,22.8L60.19,27A24,24,0,0,1,30.33,62Z" />
			<path d="M30.76,37.9,48.89,11.61a2,2,0,0,1,2.74-.54L61,17.2a2,2,0,0,1,.37,3L45.05,37.86a1,1,0,0,0,.42,1.63l2.84.95a1,1,0,0,1,.33,1.71L21.71,65a.25.25,0,0,1-.37-.32l14-22.57a1,1,0,0,0-.53-1.48l-3.54-1.18A1,1,0,0,1,30.76,37.9Z" />
		</>
	),
	funnel: (
		<path d="M65.48,10h-51a1,1,0,0,0-.83,1.6L30.49,35a5.06,5.06,0,0,1,1,3V57.57a2,2,0,0,0,.93,1.7l16.4,10.57A1,1,0,0,0,50.34,69V37.86a5.05,5.05,0,0,1,.81-2.75L66.34,11.56A1,1,0,0,0,65.48,10Z" />
	),
	info: (
		<path d="M40,70A30,30,0,1,0,10,40,30,30,0,0,0,40,70Zm3.13-48.4a4.48,4.48,0,0,0-6.41,0,4.19,4.19,0,0,0-1.3,3.1,4.5,4.5,0,0,0,7.71,3.11,4.28,4.28,0,0,0,1.35-3.11A4.13,4.13,0,0,0,43.13,21.6ZM43.76,60V33.86H33v5.59h4.19V60Z" />
	),
	microphone: (
		<>
			<path d="M55.18,37.78a1.86,1.86,0,0,1-1.67-.66L40.29,21.58a1.85,1.85,0,0,1-.39-1.74A13.88,13.88,0,1,1,55.18,37.78Z" />
			<path d="M49.5,38,38.84,25.68a1,1,0,0,0-1.49,0L17,48.1a1,1,0,0,0,0,1.33l6.11,7.09a1,1,0,0,0,1.31.16L49.31,39.52A1,1,0,0,0,49.5,38Z" />
			<path d="M55.28,55.69C52,55.64,47,57.74,39,64c-8.29,6.48-16.61,7.16-21.93,4.28a9.09,9.09,0,0,1-4.95-6.77,9.32,9.32,0,0,1,3-7.66.94.94,0,0,1,1.38.06l2,2.24a1.1,1.1,0,0,1-.05,1.45,4.23,4.23,0,0,0-1.32,3.3,4.09,4.09,0,0,0,2.36,3C22.55,65.5,28.72,65.68,36,60c8-6.27,14.26-9.42,19.39-9.34s8.56,3.43,10.92,7.8a1,1,0,0,1-.43,1.34l-2.68,1.34a1,1,0,0,1-1.36-.44C59.85,57.17,57.85,55.73,55.28,55.69Z" />
		</>
	),
	pencil: (
		<>
			<path d="M17.22,46a.25.25,0,0,0-.49,0L11,66.58A2,2,0,0,0,13.42,69L34,63.27a.25.25,0,0,0,0-.49l-6.89-1.53a3.94,3.94,0,0,1-2-1.07l-5.36-5.36a3.94,3.94,0,0,1-1.07-2Z" />
			<path d="M21.75,49.86l-1.63-7.32a1,1,0,0,1,.27-.93l21-21a.5.5,0,0,1,.84.25l1.55,7a3.94,3.94,0,0,0,1.07,2l5.36,5.36a3.94,3.94,0,0,0,2,1.07l7,1.55a.5.5,0,0,1,.25.84l-21,21a1,1,0,0,1-.93.27l-7.32-1.63a3.94,3.94,0,0,1-2-1.07l-5.36-5.36A3.94,3.94,0,0,1,21.75,49.86Z" />
			<path d="M47.82,26.82a3.94,3.94,0,0,1-1.07-2l-1.63-7.32a1,1,0,0,1,.27-.93l3.37-3.37a6,6,0,0,1,8.48,0l9.52,9.52a6,6,0,0,1,0,8.48l-3.37,3.37a1,1,0,0,1-.93.27l-7.32-1.63a3.94,3.94,0,0,1-2-1.07Z" />
		</>
	),
	phone: (
		<path d="M68.75,55.9a1.72,1.72,0,0,1,1,2.23c-1.34,3.24-5.64,10.51-17.43,11.75a8.63,8.63,0,0,1-3.46-.34A59.46,59.46,0,0,1,25.1,54.21c-9.75-10-13.3-19.9-14.41-23.83a8.52,8.52,0,0,1-.19-3.59c1.88-11.45,9.58-15.24,12.91-16.35a1.74,1.74,0,0,1,2.15,1.06l5.86,14.35a2.24,2.24,0,0,1-1.22,2.84A17.71,17.71,0,0,0,26.32,31a1.56,1.56,0,0,0-.6,1.38c.18,2.18,1.25,8.29,7.34,14.54s12.36,7.6,14.54,7.87A1.63,1.63,0,0,0,49,54.27a18.26,18.26,0,0,0,2.55-3.7,2.23,2.23,0,0,1,2.83-1.07Z" />
	),
	pin: (
		<path d="M40,70c2,0,20-14,20-39,0-13-9-21-20-21S20,18,20,31C20,56,38,70,40,70Zm0-30A10,10,0,1,0,30,30,10,10,0,0,0,40,40Z" />
	),
	"recurring-calendar": (
		<path d="M65,16H60V12a2,2,0,0,0-2-2H52a2,2,0,0,0-2,2v4H30V12a2,2,0,0,0-2-2H22a2,2,0,0,0-2,2v4H15a5,5,0,0,0-5,5V65a5,5,0,0,0,5,5H65a5,5,0,0,0,5-5V21A5,5,0,0,0,65,16ZM54.53,63h-36A1.5,1.5,0,0,1,17,61.5v-27a.22.22,0,0,1,.37-.16L29.06,45.13a.5.5,0,0,1-.34.87H24a1,1,0,0,0-1,1v9a1,1,0,0,0,1,1H48.25a2.07,2.07,0,0,1,1.32.49l5.29,4.63A.5.5,0,0,1,54.53,63ZM63,61.5a.22.22,0,0,1-.37.16L50.94,50.87a.5.5,0,0,1,.34-.87H56a1,1,0,0,0,1-1V40a1,1,0,0,0-1-1H31.75a2.07,2.07,0,0,1-1.32-.49l-5.29-4.63a.5.5,0,0,1,.33-.88h36A1.5,1.5,0,0,1,63,34.5Z" />
	),
	refresh: (
		<path d="M60.13,10.43a3,3,0,0,1,2.66,3.31l-1.36,12.7a3,3,0,0,1-2.38,2.62L47,31.52a3,3,0,1,1-1.19-5.88l6.78-1.39A20.42,20.42,0,0,0,19.38,39.78a3,3,0,1,1-6-.12A26.43,26.43,0,0,1,56.15,19.41l.68-6.31A3,3,0,0,1,60.13,10.43Zm3.15,27.2a3,3,0,0,1,2.94,3.06A26.43,26.43,0,0,1,23,60.55l-.72,6.66a3,3,0,1,1-6-.64l1.36-12.7a3,3,0,0,1,2.42-2.63l12.57-2.4a3,3,0,0,1,1.13,5.9L26.94,56A20.42,20.42,0,0,0,60.22,40.57,3,3,0,0,1,63.28,37.63Z" />
	),
	spread: (
		<>
			<path d="M24,13a4,4,0,0,0-4,4V63a4,4,0,0,0,4,4H56a4,4,0,0,0,4-4V17a4,4,0,0,0-4-4Zm2,5a1,1,0,0,0-1,1V61a1,1,0,0,0,1,1H54a1,1,0,0,0,1-1V19a1,1,0,0,0-1-1Z" />
			<path d="M63,45.79V34.21a.5.5,0,0,1,.85-.36l5.44,5.44a1,1,0,0,1,0,1.42l-5.44,5.44A.5.5,0,0,1,63,45.79Z" />
			<path d="M17,45.79V34.21a.5.5,0,0,0-.85-.36l-5.44,5.44a1,1,0,0,0,0,1.42l5.44,5.44A.5.5,0,0,0,17,45.79Z" />
		</>
	),
	"success-badge": (
		<path d="M40,10A30,30,0,1,0,70,40,30,30,0,0,0,40,10ZM58.52,33.39,35.91,55.88a1.41,1.41,0,0,1-2,0L21.13,43.12a1.42,1.42,0,0,1,0-2L24,38.19a1.42,1.42,0,0,1,2,0l8.21,8.21a.87.87,0,0,0,1.2,0L53.53,28.34a1.57,1.57,0,0,1,2.09.06l2.9,3A1.41,1.41,0,0,1,58.52,33.39Z" />
	),
	trash: (
		<>
			<path d="M45.76,10H34.24a2,2,0,0,0-1.79,1.11l-1.9,3.78A2,2,0,0,1,28.76,16H15.56a2,2,0,0,0-1.94,1.51l-1.31,5.25a1,1,0,0,0,1,1.24H66.72a1,1,0,0,0,1-1.24l-1.31-5.25A2,2,0,0,0,64.44,16H51.24a2,2,0,0,1-1.79-1.11l-1.9-3.78A2,2,0,0,0,45.76,10Z" />
			<path d="M62.79,30.19l-3.62,38a2,2,0,0,1-2,1.81H49l2.39-30L44,70H36L28.61,40,31,70H22.82a2,2,0,0,1-2-1.81l-3.62-38a2,2,0,0,1,2-2.19H60.8A2,2,0,0,1,62.79,30.19Z" />
		</>
	),
	user: (
		<>
			<circle cx="40" cy="23.5" r="13.5"/>
			<path d="M40,43A27.29,27.29,0,0,0,12.54,68.5,1.44,1.44,0,0,0,14,70H66a1.44,1.44,0,0,0,1.46-1.5A27.29,27.29,0,0,0,40,43Z" />
		</>
	),
	users: (
		<>
			<path d="M59.13,33.85A11,11,0,1,1,48.13,23,10.92,10.92,0,0,1,59.13,33.85Z" />
			<path d="M48.13,48.31A21.86,21.86,0,0,0,26.15,69a1,1,0,0,0,1,1h42a1,1,0,0,0,1-1A21.86,21.86,0,0,0,48.13,48.31Z" />
			<path d="M43.08,19.86A14.66,14.66,0,0,0,33.4,31.62a10.69,10.69,0,0,1-1.27.07A10.85,10.85,0,1,1,43.08,19.86Zm-9.7,15.48A14.53,14.53,0,0,0,40,45.77,26,26,0,0,0,25.76,57H11.13a1,1,0,0,1-1-1,21.86,21.86,0,0,1,22-20.69C32.55,35.31,33,35.32,33.38,35.34Z" />
		</>
	)
};

const SvgWrapper = styled.svg`
	display: inline-block;
	fill: currentColor;
	vertical-align: middle;
	fill-rule: evenodd;
`;

const Icon = (props: IconProps) => (
	<SvgWrapper
		className={props.className}
		viewBox="0 0 80 80"
	>
		{PATHS[props.name]}
	</SvgWrapper>
);

export default Icon;
