const origin = window.location.origin.replace(/\/\s*$/, "");

const CONFIG = {
	oidc: {
		clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
		issuer: process.env.REACT_APP_OKTA_ISSUER,
		redirectUri: `${origin}/okta/callback`,
		scopes: ["openid", "profile", "email"],
		pkce: true,
		disableHttpsCheck: false,
		restoreOriginalUri: async () => {
			window.location.replace("/google/authorize");
		}
	},
	resourceServer: {
		messagesUrl: `${origin}/api/messages`,
	}
};

export default CONFIG;
