import {
	Form,
	Formik
} from "formik";
import styled from "styled-components";

import {
	Text,
	Select,
	Toggle,
	TextArea,
	TzSelect
} from "../components/inputs";
import Button from "../components/button";
import Time from "../components/inputs/time-old";

const Heading = styled.h3`
	margin: 0.8em 0 0.2em;
	font-size: 90%;
`;

const FormWrapper = styled(Form)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 20px;
`;

const SubmitButton = styled(Button)`
	margin-top: 20px;
`;

const OPTIONS = ["foo", "bar", "baz", "foo2", "bar2", "baz2", "foo3", "bar3", "baz3", "foo4", "bar4", "baz4", "foo5", "bar5", "baz5"];

const UI = () => {
	const d = {
		text: "text",
		select: "a",
		textArea: "text area",
		toggle: true,
		tzSelect: "America/Los_Angeles"
	};

	return (
		<Formik
			initialValues={d}
			onSubmit={() => {}}
		>
			<FormWrapper>
				<Heading>Text</Heading>
				<Text accented name="text" />

				<Heading>Text (Copyable)</Heading>
				<Text accented copyable name="text" />

				<Heading>Select</Heading>
				<Select accented name="select" options={OPTIONS} />

				<Heading>Text Area</Heading>
				<TextArea accented name="textArea" />

				<Heading>Toggle</Heading>
				<Toggle name="toggle" />

				<Heading>Time Zone Select</Heading>
				<TzSelect name="tzSelect" />

				<Heading>Time Zone Select (Compact)</Heading>
				<TzSelect compact name="tzSelect" />

				<Heading>Time</Heading>
				<Time accented name="time" />

				<Heading>Time</Heading>
				<Time accented name="time" />

				<Heading>Time</Heading>
				<Time accented name="time" />

				<Heading>Time</Heading>
				<Time accented name="time" />

				<SubmitButton square type="submit">Submit</SubmitButton>
			</FormWrapper>
		</Formik>
	);
};

export default UI;
