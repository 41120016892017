import {
	useRef,
	useMemo,
	useState,
	useEffect,
	MutableRefObject,
} from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

import { resolveFormEvent } from "./wrap";

import useStateCapsule from "../../../hooks/use-state-capsule";

import {
	EmptyProps,
	FormEventType,
	WrappedInputProps,
	ProcessedEventHandler
} from "../../../types/forms";

interface DropBoxProps<V, IP, DP> {
	inputProps: WrappedInputProps<V> & IP;
	dropProps?: DP;
	// State
	expanded?: boolean;
	// Display
	gap?: number;
	margin?: number;
	verticalBias?: number;
	horizontalBias?: number;
	borderRadius?: number | number[];
	// Events
	onFocus?: ProcessedEventHandler<V>;
	onBlur?: ProcessedEventHandler<V>;
	// Components
	head: (props: DropComponentProps<V, IP, DP>) => JSX.Element;
	body?: (props: DropComponentProps<V, IP, DP>) => JSX.Element;
	children?: JSX.Element | JSX.Element[];
	className?: string;
}

export interface DropComponentProps<V = any, IP = EmptyProps, DP = EmptyProps> {
	dropMeta: DropMeta;
	inputProps: WrappedInputProps<V> & IP;
	dropProps: DP;
}

interface QueuedEvent<V> {
	type: FormEventType;
	handler: ProcessedEventHandler<V>;
	event: any;
}

interface DropHeadProps {
	dropHeadRef: MutableRefObject<HTMLDivElement | null>;
	children: any;
}

interface DropBodyProps {
	dropMeta: DropMeta;
	dropBodyRef: MutableRefObject<HTMLDivElement | null>;
	children: any;
}

interface DropBodyWrapperProps {
	stl: DropStyle | null;
	dropMeta: DropMeta;
}

interface DropStyle {
	visibility: "visible";
	top?: string;
	bottom?: string;
	left?: string;
	right?: string;
	minWidth: string;
	maxWidth: string;
	maxHeight: string
}

export interface DropMeta {
	hash: string;
	style: DropStyle | null;
	headBorderRadius: BorderRadius;
	bodyBorderRadius: BorderRadius;
}

interface BorderRadius {
	corners: Corners;
	composite: string;
}

type Corners = [number, number, number, number];

const DropBoxWrapper = styled.div`

`;

const DropHeadWrapper = styled.div`
	display: flex;
`;

const DropBodyWrapper = styled.div.attrs<DropBodyWrapperProps>(p => ({
	style: p.stl
}))<DropBodyWrapperProps>`
	display: flex;
	flex-direction: column;
	position: fixed;
	visibility: hidden;
	border-radius: ${p => p.dropMeta.bodyBorderRadius.composite};
	overflow: hidden;
	z-index: 100000;
`;

const DropBodyContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: inherit;
	overflow: hidden;
`;

function DropBox<V, IP = EmptyProps, DP = EmptyProps>(
	props: DropBoxProps<V, IP, DP>
) {
	const dropWrapperRef = useRef(null as HTMLDivElement | null);
	const dropHeadRef = useRef(null as HTMLDivElement | null);
	const dropBodyRef = useRef(null as HTMLDivElement | null);

	const [running, setRunning] = useState(false);
	const [dropMeta, setDropMeta] = useState(() => ({
		hash: "",
		style: null,
		headBorderRadius: mkBorderRadius(props.borderRadius || 0),
		bodyBorderRadius: mkBorderRadius(props.borderRadius || 0)
	} as DropMeta));

	const queuedEvent = useStateCapsule(null as QueuedEvent<V> | null);

	const dispatchEvent = (
		type: FormEventType,
		handler: ProcessedEventHandler<V>,
		event: any
	) => {
		const formEvent = resolveFormEvent(
			props.inputProps.name,
			{
				type: "focus",
				value: props.inputProps.value,
				originalEvent: event
			}
		);

		handler(formEvent);
	};

	const queueEvent = (
		type: FormEventType,
		handler: ProcessedEventHandler<V> | undefined,
		event: any
	) => {
		if (!handler)
			return;

		const currentQe = queuedEvent.get();

		if (!currentQe || type !== "blur" || currentQe.type !== "focus") {
			queuedEvent.set({
				type,
				handler,
				event
			});
		}

		const dispatch = () => {
			const qe = queuedEvent.get();
			if (!qe)
				return;

			dispatchEvent(
				qe.type,
				qe.handler,
				qe.event
			);

			queuedEvent.set(null);
		};

		if (!currentQe) {
			if (!props.expanded && queuedEvent.get()!.type === "focus")
				dispatch();
			else
				requestAnimationFrame(dispatch);
		}
	};

	const inScope = (node: Node) => {
		const wWrapper = dropWrapperRef.current,
			bWrapper = dropBodyRef.current;

		if (wWrapper && hasAncestor(node, wWrapper))
			return true;

		return Boolean(bWrapper) && hasAncestor(node, bWrapper!);
	};

	const handleFocus = (evt: any) => {
		if (inScope(evt.target))
			queueEvent("focus", props.onFocus, evt);
	};

	const handleBlur = (evt: any) => {
		if (inScope(evt.target))
			queueEvent("blur", props.onBlur, evt);
	};

	const handleClick = (evt: any) => {
		if (props.expanded) {
			if (!inScope(evt.target))
				queueEvent("blur", props.onBlur, evt);
			else
				queueEvent("focus", props.onFocus, evt);
		}
	};

	const handleVisibilityChange = (evt: any) => {
		if (document.visibilityState === "hidden" && props.expanded)
			queueEvent("blur", props.onBlur, evt);
	};

	const handleKeyDown = (evt: any) => {
		if (!props.expanded)
			return;

		switch (evt.key) {
			case "Tab":
				handleTabbing(evt);
				break;

			case "Escape":
				handleEscape(evt);
		}
	};

	const handleTabbing = (evt: any) => {
		const hWrapper = dropHeadRef.current,
			bWrapper = dropBodyRef.current;

		if (!hWrapper || !bWrapper)
			return;

		const hTabbables = getTabbables(hWrapper),
			bTabbables = getTabbables(bWrapper);

		if (evt.shiftKey) {
			if (document.activeElement === bTabbables[0] && hTabbables.length) {
				(hTabbables[hTabbables.length - 1] as any).focus();
				evt.preventDefault();
			}
		} else {
			if (document.activeElement === hTabbables[hTabbables.length - 1] && bTabbables.length) {
				(bTabbables[0] as any).focus();
				evt.preventDefault();
			} else if (document.activeElement === bTabbables[bTabbables.length - 1]) {
				const refElement = hTabbables[hTabbables.length - 1] || hWrapper,
					nextTabbable = getNextTabbable(refElement);

				if (nextTabbable) {
					(nextTabbable as any).focus();
					evt.preventDefault();
				}
			}
		}
	};

	const handleEscape = (evt: any) => {
		if (props.expanded)
			queueEvent("blur", props.onBlur, evt);
	};

	const updateDrop = () => {
		if (!props.expanded) {
			setRunning(false);

			setDropMeta({
				hash: "",
				style: null,
				headBorderRadius: mkBorderRadius(props.borderRadius || 0),
				bodyBorderRadius: mkBorderRadius(props.borderRadius || 0)
			});

			return;
		}

		const baseBorderRadius = mkBorderRadius(props.borderRadius || 0);

		const wbcr = dropWrapperRef.current ?
				dropWrapperRef.current.getBoundingClientRect() :
				null,
			bbcr = dropBodyRef.current ?
				dropBodyRef.current?.getBoundingClientRect() :
				null,
			gap = typeof props.gap == "number" ?
				props.gap :
				0,
			margin = typeof props.margin == "number" ?
				props.margin :
				0,
			verticalBias = typeof props.verticalBias == "number" ?
				props.verticalBias :
				0.75,
			horizontalBias = typeof props.horizontalBias == "number" ?
				props.horizontalBias :
				1;

		const wHash = hashBcr(wbcr),
			bHash = hashBcr(bbcr),
			hash = `${wHash}/${bHash}/${margin}/${verticalBias}/${horizontalBias}/${baseBorderRadius.composite}`;

		setDropMeta(m => {
			if (m.hash === hash || !wbcr || !bbcr)
				return m;

			const style = {
				visibility: "visible",
				minWidth: `${wbcr.width}px`
			} as DropStyle;

			const topRealEstate = wbcr.top - margin,
				bottomRealEstate = window.innerHeight - wbcr.bottom - margin - gap,
				verticalRealEstate = topRealEstate + bottomRealEstate,
				leftRealEstate = wbcr.left - margin,
				rightRealEstate = window.innerWidth - wbcr.right - margin - gap,
				horizontalRealEstate = leftRealEstate + rightRealEstate,
				placeTop = topRealEstate / verticalRealEstate > verticalBias,
				placeLeft = leftRealEstate / horizontalRealEstate > horizontalBias || wbcr.left + bbcr.width > window.innerWidth,
				c = baseBorderRadius.corners;
			let headBorderRadius = baseBorderRadius,
				bodyBorderRadius = baseBorderRadius;

			// Vertical anchoring
			if (placeTop) {
				style.bottom = `${window.innerHeight - wbcr.top + gap}px`;
				style.maxHeight = `${topRealEstate}px`;

				if (gap <= 0)
					headBorderRadius = mkBorderRadius([0, 0, c[2], c[3]]);
			} else {
				style.top = `${wbcr.bottom + gap}px`;
				style.maxHeight = `${bottomRealEstate}px`;

				if (gap <= 0)
					headBorderRadius = mkBorderRadius([c[0], c[1], 0, 0]);
			}

			// Horizontal/vertical anchoring
			if (placeLeft) {
				style.right = `${window.innerWidth - wbcr.right}px`;
				style.maxWidth = `${leftRealEstate}px`;
			} else {
				style.left = `${wbcr.left}px`;
				style.maxWidth = `${rightRealEstate}px`;
			}

			const getBBR = (flexIndex: number, squareIndex: number): BorderRadius => {
				const corners = baseBorderRadius.corners.slice();

				corners[flexIndex] = Math.min(
					corners[flexIndex],
					Math.max(bbcr.width - wbcr.width, 0)
				);
				corners[squareIndex] = 0;

				return mkBorderRadius(corners);
			};

			if (gap <= 0) {
				if (placeTop) {
					if (placeLeft)
						bodyBorderRadius = getBBR(3, 2);
					else
						bodyBorderRadius = getBBR(2, 3);
				} else {
					if (placeLeft)
						bodyBorderRadius = getBBR(0, 1);
					else
						bodyBorderRadius = getBBR(1, 0);
				}
			}

			return {
				hash,
				style,
				headBorderRadius,
				bodyBorderRadius
			};
		});

		requestAnimationFrame(currentDropUpdater.get());
	};

	const currentDropUpdater = useStateCapsule(updateDrop, true);

	const handlers = useStateCapsule({
		handleFocus,
		handleBlur,
		handleClick,
		handleKeyDown,
		handleVisibilityChange
	}, true);

	useEffect(
		() => {
			const relayFocus = (evt: any) => {
				handlers.get().handleFocus(evt);
			};

			const relayBlur = (evt: any) => {
				handlers.get().handleBlur(evt);
			};

			const relayClick = (evt: any) => {
				handlers.get().handleClick(evt);
			};

			const relayKeyDown = (evt: any) => {
				handlers.get().handleKeyDown(evt);
			};

			const relayVisibilityChange = (evt: any) => {
				handlers.get().handleVisibilityChange(evt);
			};

			document.body.addEventListener("focusin", relayFocus);
			document.body.addEventListener("focusout", relayBlur);
			document.body.addEventListener("mousedown", relayClick);
			document.body.addEventListener("mouseup", relayClick);
			document.body.addEventListener("click", relayClick);
			document.body.addEventListener("keydown", relayKeyDown);
			document.addEventListener("visibilitychange", relayVisibilityChange);

			return () => {
				document.body.removeEventListener("focusin", relayFocus);
				document.body.removeEventListener("focusout", relayBlur);
				document.body.removeEventListener("mousedown", relayClick);
				document.body.removeEventListener("mouseup", relayClick);
				document.body.removeEventListener("click", relayClick);
				document.body.removeEventListener("keydown", relayKeyDown);
				document.removeEventListener("visibilitychange", relayVisibilityChange);
			};
		},
		[]
	);

	if (props.expanded && !running) {
		setRunning(true);
		updateDrop();
	}

	const componentProps = {
		dropMeta,
		inputProps: props.inputProps,
		dropProps: props.dropProps || {} as DP
	} as DropComponentProps<V, IP, DP>;

	let body = null;

	if (props.expanded) {
		const children = props.body ?
			<props.body {...componentProps} /> :
			props.children;

		body = (
			<DropBody
				dropMeta={dropMeta}
				dropBodyRef={dropBodyRef}
			>
				{children}
			</DropBody>
		);
	}

	return (
		<DropBoxWrapper
			className={props.className}
			ref={dropWrapperRef}
		>
			<DropHead dropHeadRef={dropHeadRef}>
				<props.head {...componentProps} />
			</DropHead>
			{body}
		</DropBoxWrapper>
	);
}

const DropHead = (props: DropHeadProps) => {
	return (
		<DropHeadWrapper ref={props.dropHeadRef}>
			{props.children}
		</DropHeadWrapper>
	);
};

const DropBody = (props: DropBodyProps) => {
	const root = useMemo(
		() => document.querySelector("#ui-root"),
		[]
	);

	const wrapper = (
		<DropBodyWrapper
			stl={props.dropMeta.style}
			dropMeta={props.dropMeta}
		>
			<DropBodyContentWrapper ref={props.dropBodyRef}>
				{props.children}
			</DropBodyContentWrapper>
		</DropBodyWrapper>
	);

	if (root)
		return createPortal(wrapper, root);

	return wrapper;
};

const hasAncestor = (node: Node, ancestor: Node): boolean => {
	let n = node as Node | null;

	while (n && n !== document.body) {
		if (n === ancestor)
			return true;

		n = n.parentNode;
	}

	return false;
};

const hashBcr = (bcr: DOMRect | null) => {
	if (!bcr)
		return "";

	return `${
			Math.round(bcr.top)
		}:${
			Math.round(bcr.left)
		}:${
			Math.round(bcr.width)
		}:${
			Math.round(bcr.height)
		}`;
};

const resolveBorderRadiusCorners = (
	source: number | number[]
): Corners => {
	const src = typeof source == "number" ?
		[source] :
		source;

	const corners = [];

	for (let i = 0; i < 4; i++)
		corners.push(src[i % src.length]);

	return corners as Corners;
};

const mkBorderRadius = (
	source: number | number[]
): BorderRadius => {
	const corners = resolveBorderRadiusCorners(source);

	return {
		corners,
		composite: corners
			.map(c => c ? `${c}px` : "0")
			.join(" ")
	};
};

const getTabbables = (element: Element) => {
	const elements = [] as Element[];

	const traverse = (e: Element) => {
		const tabIndex = getTabIndex(e as any);

		if (tabIndex > -1)
			elements.push(e);

		const children = e.children;

		for (let i = 0, l = children.length; i < l; i++)
			traverse(children[i]);
	};

	traverse(element);

	elements.sort((e, e2) => (
		(e as any).tabIndex - (e2 as any).tabIndex
	));

	return elements;
};

const getTabIndex = (element: Element): number => {
	if ((element as any).disabled)
		return -1;

	if (typeof (element as any).tabIndex == "number")
		return (element as any).tabIndex;

	return -1;
};

// Get next tabbable element by walking through the entire body
// If the specified element hasn't been reached, then any candidate must have a tabIndex
// value that is strictly larger than the one of the specified element
// If the specified element has tabIndex -1, then the minimum tabIndex is 0 for preceding nodes
// Elements following the specified element may have the same tabIndex
// The element with the lowest possible tabIndex is the next tabbable element
const getNextTabbable = (element: Element): Element | null => {
	const elementTabIndex = getTabIndex(element),
		minPrecedingTabIndex = Math.max(elementTabIndex, 0),
		walker = document.createTreeWalker(document.body, NodeFilter.SHOW_ELEMENT);
	let foundElement = false,
		minTabIndex = Infinity,
		minElement = null;

	while (true) {
		const e = walker.nextNode() as Element | null;
		if (!e)
			break;

		const tabIndex = getTabIndex(e);
		if (tabIndex === -1)
			continue;

		if (e === element)
			foundElement = true;
		else if (foundElement) {
			if (tabIndex >= elementTabIndex && tabIndex < minTabIndex) {
				minTabIndex = tabIndex;
				minElement = e;
			}
		} else {
			if (tabIndex > minPrecedingTabIndex && tabIndex < minTabIndex) {
				minTabIndex = tabIndex;
				minElement = e;
			}
		}

		if (minTabIndex === elementTabIndex)
			break;
	}

	return minElement;
};

export default DropBox;
