import { Link } from "react-router-dom";
import styled from "styled-components";
import { useAppSelector } from "./state/hooks";
import Avatar from "./components/avatar";
import Routes from "./views";
import Icon from "./components/icon";
import Button from "./components/button";
import {useOktaAuth} from "@okta/okta-react";
import {useEffect} from "react";
import ReactGA from 'react-ga4';

const Header = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: sticky;
	top: 0;
	height: ${p => p.theme.headerHeight};
	padding: ${p => p.theme.padding};
	background: ${p => p.theme.headerBackground};
	border-bottom: ${p => p.theme.boundaryBorder};
	z-index: 999;
`;

const HeaderLeft = styled.div`
	display: flex;
	align-items: center;

	.logo-link {
		display: flex;
		align-items: center;
		height: 30px;
		padding: 0 5px;

		img {
			height: 65%;
		}
	}
`;

const HeaderRight = styled.div`
	display: flex;
	align-items: center;
	
	.logout-button {
		margin-left:20px;
	}
	
`;

const UserLink = styled(Link)`
	display: flex;
	align-items: center;
	color: inherit;
`;

const UserName = styled.h2`
	margin: 0 1em 0 0;
	font-size: 100%;
	line-height: 1;
`;

const LogoutIcon = styled(Icon)`
	//flex-shrink: 0;
	width: 20px;
	margin:-5px;
	//margin-right: -5px;
	// color: ${p => p.theme.extraLightColor};
`;
const Main = styled.main`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	background: ${p => p.theme.background};
	color: ${p => p.theme.color};
`;

const Footer = styled.footer`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: ${p => p.theme.padding};
	background: ${p => p.theme.footerBackground};
	border-top: ${p => p.theme.boundaryBorder};
	
	.copyright {
		color: ${p => p.theme.lightColor};
	}

	@media ${p => p.theme.mobileMedia} {
		flex-direction: column;
	}
`;

const App = () => {
	const employee = useAppSelector(state => state.client.employee);
	let headerRight = null;
	const {oktaAuth} = useOktaAuth();

	if (employee) {
		headerRight = (
			<HeaderRight>
				<UserLink to="/account">
					<UserName>{employee.name}</UserName>
					<Avatar picture={employee.picture} />
				</UserLink>
				<Button
					className="logout-button"
					square
					onClick={(e:any) => {
						e.preventDefault();
						oktaAuth.signOut({ postLogoutRedirectUri: window.location.origin });
					}}
				>
					Log out
				</Button>
			</HeaderRight>
		);
	}
	
	useEffect(() => {
        if (['production','prod', 'stage', 'stg'].includes(process.env.NODE_ENV)) {
			ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '');
		}
    }, []);

	return (
		<>
			<Header>
				<HeaderLeft>
					<Link to="/" className="logo-link">
						<img src="/vector/logo-full.svg" alt="Company Logo"/>
					</Link>
				</HeaderLeft>
				{headerRight}
			</Header>
			<Main>
				<Routes />
			</Main>
			<Footer>
				<span className="copyright">
					Copyright &copy; {new Date().getFullYear()} Palo Alto Networks
				</span>
			</Footer>
		</>
	);
};

export default App;
