import {
	createSlice,
	PayloadAction
} from "@reduxjs/toolkit";
import { CLIENT_DATA_VERSION } from "../../data/constants";

import { getLocalTimeZone } from "../../util/time";

import { ClientEmployee } from "../../../../types/employee";
import { ClientEventTemplate } from "../../../../types/events";

interface ClientState {
	dataVersion: number;
	employee: ClientEmployee | null;
	timeZone: string;
	referenceTimeZone: string;
}

const localRawData = localStorage.getItem("client-data"),
	localData = localRawData ?
		JSON.parse(localRawData) :
		null,
	dataIsOutdated = localData && localData.dataVersion !== CLIENT_DATA_VERSION;

// If the data version doesn't match, clear local storage data
// (including Okta state) and redirect to the landing page so
// that the user can log in again
if (dataIsOutdated) {
	console.warn("OUTDATED DATA - CLEARING SESSION");
	localStorage.clear();
	window.location.href = "/";
}

const DEFAULT_TIMEZONE = getLocalTimeZone();

const initialState: ClientState = {
	// Client data version control
	dataVersion: CLIENT_DATA_VERSION,
	// Employee data
	employee: null,
	// General state
	timeZone: DEFAULT_TIMEZONE,
	referenceTimeZone: DEFAULT_TIMEZONE,
	...localData
};

if (!dataIsOutdated)
	localStorage.setItem("client-data", JSON.stringify(initialState));

export const slice = createSlice({
	name: "client",
	initialState,
	reducers: {
		setEmployee: (state, action: PayloadAction<ClientEmployee | null>) => {
			state.employee = action.payload;
		},
		setEvents: (state, action: PayloadAction<ClientEventTemplate[]>) => {
			state.employee!.events = action.payload;
		},
		touchEvents: (state, action: PayloadAction<ClientEventTemplate[]>) => {
			state.employee!.events = state.employee!.events;
		},
		setTimeZone: (state, action: PayloadAction<string>) => {
			state.timeZone = action.payload;
		},
		setReferenceTimeZone: (state, action: PayloadAction<string>) => {
			state.referenceTimeZone = action.payload;
		}
	}
});

export default slice.reducer;
export const {
	setEmployee,
	setEvents,
	setTimeZone,
	setReferenceTimeZone,
	touchEvents
} = slice.actions;
