const lightTheme = {
	// Backgrounds
	background: "#fbf6f6",
	backgroundAlt: "#f7efef",
	backgroundLight: "#fff9f9",
	cardBackground: "white",
	headerBackground: "white",
	footerBackground: "white",
	popBackground: "#fa582d",
	popBackgroundAlt: "#00c0e8",
	popBackgroundDisabled: "#e6d5cf",
	popBackgroundSubdued: "#da9d8e",
	popBackgroundLight: "#ffd9cf",
	popBackgroundLightAlt: "#e66441",
	unavailableBackground: "#e8d9d6",
	selectedBackground: "#fbf6f6",
	unselectedBackground: "#fbf6f5",
	takenBackground: "#fbf1f1",
	takenCellBackground: "#fdf5f5",
	takenCellForeground: "#ead1d1",
	// Colors
	color: "#494240",
	lightColor: "#866d67",
	tintedColor: "#755f59",
	separatorColor: "#e4dcdc",
	extraLightColor: "#ae9791",
	// NOTE: breaks AA with popBackground - change if accessibility becomes a problem
	popContrastColor: "white",
	popContrastColorDisabled: "#141414",
	popContrastColorDisabledLight: "#846155",
	boundaryBorderColor: "#efe2df",
	// Presentational
	headerHeight: "50px",
	padding: "10px",
	borderRadius: "3px",
	boundaryBorder: "1px solid #efe2df",
	boxShadow: "2px 2px 10px rgba(156, 67, 44, 0.1)",
	// Responsiveness
	mobileMedia: "(max-width: 700px)"
};

export default lightTheme;
