import styled from "styled-components";

import wrap from "./core/wrap";

import { WrappedInputProps } from "../../types/forms";

interface DateProps {
	step?: number;
}

const DateWrapper = styled.input<WrappedInputProps<string>>`
	${p => p.baseStyle};
	width: 140px;
	height: 35px;
`;

const Text = wrap({
	observe: ["change"],
	component: (props: WrappedInputProps<string, DateProps>) => (
		<DateWrapper
			{...props}
			type="date"
		/>
	)
});

export default Text;
