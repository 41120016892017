import styled from "styled-components";
import Icon from "./icon";

interface TipProps {
	children: any;
	title?: string;
	className?: string;
}

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 5px 10px;
	background: ${p => p.theme.background};
	border-radius: ${p => p.theme.borderRadius};
	line-height: 1.2;
`;

const TipIcon = styled(Icon)`
	flex-shrink: 0;
	height: 1.5em;
	margin-right: 0.5em;
	opacity: 0.6;
`;

const TipContent = styled.div``;

const TipTitle = styled.h2`
	font-size: 90%;
	text-transform: uppercase;
	margin: 0.2em 0;
`;

const Tip = (props: TipProps) => {
	const title = props.title ?
		<TipTitle>{props.title}</TipTitle> :
		null;

	return (
		<Wrapper className={props.className}>
			<TipIcon name="info" />
			<TipContent>
				{title}
				{props.children}
			</TipContent>
		</Wrapper>
	);
}

export default Tip;
