import styled from "styled-components";

interface AspectProps {
	aspect: number;
}

const AspectBox = styled.div<AspectProps>`
	width: 100%;
	padding-bottom: ${p => (1 / p.aspect) * 100}%;
`;

export default AspectBox;
