import { Option } from "../types/forms";
import {ScheduleType} from "../../../types/events";

const API_URL = `${process.env.REACT_APP_BACKEND_URL}/api/v1`;

const CLIENT_DATA_VERSION = 1;

const TIMEZONE = "America/Los_Angeles",
	PERIODICITY = 0.25,
	DISPLAY_PERIODICITY = 0.5;

const DAYS = [
	"Sunday",
	"Monday",
	"Tuesday",
	"Wednesday",
	"Thursday",
	"Friday",
	"Saturday"
];

const ACTIVE_WEEK_DAYS = {
	mon: true,
	tue: true,
	wed: true,
	thu: true,
	fri: true
};

const ACTIVE_DAYS = {
	...ACTIVE_WEEK_DAYS,
	sat: true,
	sun: true
};

const DAYS_SHORT = [
	"Sun",
	"Mon",
	"Tue",
	"Wed",
	"Thu",
	"Fri",
	"Sat"
];

const MONTHS_SHORT = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec"
];

const TIMEZONES = [
	{
		name: "American Samoa",
		shortName: "SST",
		zone: "Pacific/Apia"
	},
	{
		name: "Hawaii",
		shortName: "HST",
		zone: "Pacific/Honolulu"
	},
	{
		name: "Alaska",
		shortName: "AKT",
		zone: "America/Anchorage"
	},
	{
		name: "Pacific",
		shortName: "PST",
		zone: "America/Los_Angeles"
	},
	{
		name: "Mountain",
		shortName: "MST",
		zone: "America/Denver"
	},
	{
		name: "Arizona",
		shortName: "PNT",
		zone: "America/Phoenix"
	},
	{
		name: "Central",
		shortName: "CST",
		zone: "America/Chicago"
	},
	{
		name: "Eastern",
		shortName: "EST",
		zone: "America/New_York"
	},
	{
		name: "Venezuela",
		shortName: "VET",
		zone: "America/Caracas"
	},
	{
		name: "Brazil (São Paulo)",
		shortName: "BRT",
		zone: "America/Sao_Paulo"
	},
	{
		name: "Greenland (Eastern)",
		shortName: "EGT",
		zone: "America/Scoresbysund"
	},
	{
		name: "Cabo Verde",
		shortName: "CVE",
		zone: "Atlantic/Cape_Verde"
	},
	{
		name: "Abidjan",
		shortName: "GMT",
		zone: "Africa/Abidjan"
	},
	{
		name: "London",
		shortName: "GMT",
		zone: "Europe/London"
	},
	{
		name: "Central Europe",
		shortName: "ECT",
		zone: "Europe/Paris"
	},
	{
		name: "Cairo",
		shortName: "EET",
		zone: "Africa/Cairo"
	},
	{
		name: "Jerusalem",
		shortName: "IDT",
		zone: "Asia/Jerusalem"
	},
	{
		name: "Addis Ababa",
		shortName: "EAT",
		zone: "Africa/Nairobi"
	},
	{
		name: "Yerevan",
		shortName: "AMT",
		zone: "Asia/Yerevan"
	},
	{
		name: "Kabul",
		shortName: "AFT",
		zone: "Asia/Kabul"
	},
	{
		name: "Dushanbe",
		shortName: "TJT",
		zone: "Asia/Dushanbe"
	},
	{
		name: "Kolkata",
		shortName: "IST",
		zone: "Asia/Kolkata"
	},
	{
		name: "Kathmandu",
		shortName: "NPT",
		zone: "Asia/Kathmandu"
	},
	{
		name: "Dhaka",
		shortName: "BST",
		zone: "Asia/Dhaka"
	},
	{
		name: "Bangkok",
		shortName: "ICT",
		zone: "Asia/Bangkok"
	},
	{
		name: "Hong Kong",
		shortName: "HKT",
		zone: "Asia/Hong_Kong"
	},
	{
		name: "Tokyo",
		shortName: "JST",
		zone: "Asia/Tokyo"
	},
	{
		name: "Adelaide",
		shortName: "ACST",
		zone: "Australia/Adelaide"
	},
	{
		name: "Brisbane",
		shortName: "AEST",
		zone: "Australia/Brisbane"
	},
	{
		name: "Sydney",
		shortName: "AEST",
		zone: "Australia/Sydney"
	},
	{
		name: "Kamchatka",
		shortName: "PETT",
		zone: "Asia/Kamchatka"
	},
	{
		name: "Auckland",
		shortName: "NZST",
		zone: "Pacific/Auckland"
	},
	{
		name: "Chatham Islands",
		shortName: "CHAST",
		zone: "Pacific/Chatham"
	}
];

const MEETING_TYPE_OPTIONS: Option<string>[] = [
	{
		value: "me",
		label: "Book with Me",
		icon: "user"
	},
	{
		value: "team",
		label: "Book with Teammates",
		icon: "users"
	}
	,
	{
		value: "office-hour",
		label: "Schedule Office Hour",
		icon: "pin"
	}
];

const DURATION_OPTIONS: Option<number>[] = [
	{
		value: 15,
		label: "15 Minutes"
	},
	{
		value: 30,
		label: "30 Minutes"
	},
	{
		value: 45,
		label: "45 Minutes"
	},
	{
		value: 60,
		label: "60 Minutes"
	},
	{
		value: 75,
		label: "75 Minutes"
	},
	{
		value: 90,
		label: "90 Minutes"
	},
	{
		value: 120,
		label: "120 Minutes"
	}
];

const FULL_DURATION_OPTIONS: Option<number | null>[] = [
	{
		value: null,
		label: "Any",
		icon: "controls"
	},
	{
		value: -1,
		label: "Custom",
		icon: "clock"
	},
	...DURATION_OPTIONS as Option<number | null>[],
];

const VENUE_OPTIONS: Option<string>[] = [
	{
		value: "zoom",
		label: "Zoom",
		icon: "camera"
	},
	{
		value: "google-meet",
		label: "Meet",
		icon: "camera"
	},
	{
		value: "phone",
		label: "Phone",
		icon: "phone"
	}
];

const FULL_VENUE_OPTIONS: Option<string | null>[] = [
	{
		value: null,
		label: "Any",
		icon: "controls"
	},
	...VENUE_OPTIONS as Option<string | null>[]
];

const SCHEDULE_TYPE_OPTIONS: Option<ScheduleType>[] = [
	{
		value: "time",
		label: "Time",
		icon: "clock"
	},
	{
		value: "exact-time",
		label: "Exact Time",
		icon: "calendar"
	}
];

const DAY_OPTIONS: Option<number>[] = [
	{
		value: 1,
		label: "M"
	},
	{
		value: 2,
		label: "T"
	},
	{
		value: 3,
		label: "W"
	},
	{
		value: 4,
		label: "T"
	},
	{
		value: 5,
		label: "F"
	}
];

const FULL_DAY_OPTIONS: Option<number | null>[] = [
	{
		value: null,
		label: "Any"
	},
	...DAY_OPTIONS as Option<number | null>[]
];

const TOPIC_OPTIONS: Option<string | null>[] = [
	{
		value: null,
		label: "Topic",
		icon: "cross"
	},
	{
		value: "Compute",
		label: "Compute",
		icon: "pin"
	},
	{
		value: "Cortex",
		label: "Cortex",
		icon: "pin"
	},
	{
		value: "Prisma",
		label: "Prisma",
		icon: "pin"
	},
	{
		value: "Strata",
		label: "Strata",
		icon: "pin"
	}
];

const BUFFER_TIME_OPTIONS: Option<number>[] = [
	{
		value: 4,
		label: "1 hour" 
	},
	{
		value: 8,
		label: "2 hours" 
	},
	{
		value: 16,
		label: "4 hours" 
	},
	{
		value: 32,
		label: "8 hours" 
	},
	{
		value: 48,
		label: "12 hours" 
	},
	{
		value: 96,
		label: "24 hours" 
	},
	{
		value: 192,
		label: "48 hours" 
	}
]

const ONETIME_DURATION_OPTIONS: Option<number>[] = [
	{
		value: 60,
		label: "1 Hour"
	},
	{
		value: 2*60,
		label: "2 Hours"
	},
	{
		value: 6*60,
		label: "6 Hours"
	},
	{
		value: 12*60,
		label: "12 Hours"
	},
	{
		value: 24*60,
		label: "24 Hours"
	},
	{
		value: 2*24*60,
		label: "2 Days"
	},
	{
		value: 3*24*60,
		label: "3 Days"
	},
	{
		value: 4*24*60,
		label: "4 Days"
	},
	{
		value: 7*24*60,
		label: "1 Week"
	},
	{
		value: 14*24*60,
		label: "2 Weeks"
	}
];

export {
	API_URL,
	CLIENT_DATA_VERSION,
	TIMEZONE,
	PERIODICITY,
	DISPLAY_PERIODICITY,
	DAYS,
	DAYS_SHORT,
	MONTHS_SHORT,
	ACTIVE_WEEK_DAYS,
	ACTIVE_DAYS,
	TIMEZONES,
	MEETING_TYPE_OPTIONS,
	DURATION_OPTIONS,
	FULL_DURATION_OPTIONS,
	VENUE_OPTIONS,
	FULL_VENUE_OPTIONS,
	SCHEDULE_TYPE_OPTIONS,
	DAY_OPTIONS,
	FULL_DAY_OPTIONS,
	TOPIC_OPTIONS,
	BUFFER_TIME_OPTIONS,
	ONETIME_DURATION_OPTIONS
};
