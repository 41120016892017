import {
	mkDate,
	getUtcBlock,
	getClosestBlockEnd,
	getClosestBlockStart
} from "./time";

import { DateValue } from "../types/time";

export interface TimeEventConfig {
	type: TimeEventType;
	start: DateValue;
	end?: DateValue;
	duration?: number;
	title?: string;
	eventId?: string;
	staticLink?: string;
	showInGui: boolean;
	eventType: string;
	topic: string;
}

type TimeEventType = "taken" | "entry" | "selectable";
type EventBlock = Record<string, TimeEvent[]>;

export default class TimeBlocks {
	blocks: EventBlock;

	constructor() {
		this.blocks = {};
	}

	add(configOrEvent: TimeEventConfig | TimeEvent): TimeEvent {
		const event = TimeEvent.resolve(configOrEvent),
			startBlock = getUtcBlock(event.start),
			endBlock = getUtcBlock(event.end);

		for (let i = startBlock; i < endBlock; i++) {
			if (!this.blocks.hasOwnProperty(i))
				this.blocks[i] = [event];
			else {
				this.blocks[i] = [...this.blocks[i], event]
					.sort((e, e2) => e.precedence - e2.precedence);
			}
		}

		return event;
	}

	get(...args: DateValue[]): TimeEvent | null {
		const block = args.length === 1 && typeof args[0] === "number" && args[0] < 1e7 ?
			args[0] :
			getUtcBlock(...args);

		const events = this.blocks[block];
		if (!events)
			return null;

		return events[events.length - 1];
	}

	has(...args: DateValue[]): boolean {
		return this.get(...args) !== null;
	}

	delete(event: TimeEvent): boolean {
		const startBlock = getUtcBlock(event.start),
			endBlock = getUtcBlock(event.end);
		let deleted = false;

		for (let i = startBlock; i < endBlock; i++) {
			const events = this.blocks[i];
			if (!events)
				continue;

			const es = events.filter(e => e !== event);

			if (es.length !== events.length)
				deleted = true;

			if (!es.length)
				delete this.blocks[i];
			else
				this.blocks[i] = es;
		}

		return deleted;
	}
}

class TimeEvent {
	start: number;
	end: number;
	duration: number;
	startBlock: number;
	name: string;
	precedence: number;
	referenceId?: string;
	showInGui: boolean;
	eventType: string;
	topic: string;

	constructor(start: number, end: number, name: string, referenceId: string|undefined, showInGui: boolean, eventType: string, topic: string) {
		this.start = start;
		this.end = end;
		this.duration = (end - start) / (60 * 1000);
		this.startBlock = getUtcBlock(this.start);
		this.name = name;
		this.precedence = -1;
		this.referenceId = referenceId;
		this.showInGui = showInGui;
		this.eventType = eventType;
		this.topic = topic;
	}

	static resolve(configOrEvent: TimeEventConfig | TimeEvent) {
		if (configOrEvent instanceof TimeEvent) {
			return configOrEvent;
		}

		let start = getClosestBlockStart(configOrEvent.start),
			end = typeof configOrEvent.duration === "number" ?
				getClosestBlockEnd(mkDate(configOrEvent.start).getTime() + configOrEvent.duration * 60 * 1000)
				:
				getClosestBlockEnd(configOrEvent.end);

		let ctor;

		switch (configOrEvent.type) {
			case "taken":
				ctor = TakenTimeEvent;
				break;

			case "entry":
				ctor = EntryTimeEvent;

				if(typeof configOrEvent.duration === 'number')
					end = start + (configOrEvent.duration * 60 * 1000);
				break;

			case "selectable":
				ctor = SelectableTimeEvent;

				if(typeof configOrEvent.end === 'string')
					end = new Date(configOrEvent.end).getTime();
				break;
		}

		return new ctor(
			start,
			end,
			configOrEvent.title || "",
			configOrEvent.staticLink || "",
			configOrEvent.showInGui,
			configOrEvent.eventType,
			configOrEvent.topic,
		);
	}
}

class TakenTimeEvent extends TimeEvent {
	constructor(start: number, end: number, name: string, referenceId: string|undefined, showInGui: boolean, eventType: string, topic: string) {
		super(start, end, name, referenceId, showInGui, eventType, topic);
		this.precedence = 0;
	}
}

class EntryTimeEvent extends TimeEvent {
	constructor(start: number, end: number, name: string, referenceId: string|undefined, showInGui: boolean, eventType: string, topic: string) {
		super(start, end, name, referenceId, showInGui, eventType, topic);
		this.precedence = 2;
	}
}

class SelectableTimeEvent extends TimeEvent {
	constructor(start: number, end: number, name: string, referenceId: string|undefined, showInGui: boolean, eventType: string, topic: string) {
		super(start, end, name, referenceId, showInGui, eventType, topic);
		this.precedence = 1;
	}
}

export {
	TimeEvent,
	TakenTimeEvent,
	EntryTimeEvent,
	SelectableTimeEvent
};
