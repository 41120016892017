import { useMemo } from "react";

import { isObject } from "../util/helpers";

import {
	Option,
	OptionObject,
	CompiledOption
} from "../types/forms";

export interface Selectable<V> {
	options: CompiledOption<V>[];
	rawOptions: V[];
	selection: CompiledOption<V>[];
	rawSelection: V[];
}

interface SelectableConfig<V> {
	options: Option<V>[];
	selection: (Option<V> | CompiledOption<V>)[];
	autoSet?: boolean;
	hash?: (option: CompiledOption<V>) => string;
}

type SelectionMap<V> = Record<string, CompiledOption<V>>;

function useSelectable<V>(config: SelectableConfig<V>) {
	const rawOptions = useMemo(
		() => {
			return config.options.map((item, idx) => {
				const option = resolveCompiledOption(item, config);
				option.index = idx;
				return option;
			});
		},
		[config.options]
	) as CompiledOption<V>[];

	const selectionMap = useMemo(
		() => {
			const map = {} as SelectionMap<V>;

			for (const item of config.selection) {
				// If the selection item isn't an options object
				// or if there's no global hashing function, we
				// cannot establish a relationship between input and output
				// and we have to iteratively check every option against
				// the selected item instead
				if (!isOptionsObject(item) && typeof config.hash != "function") {
					for (const option of rawOptions) {
						if (item === option.value) {
							map[option.computedHash] = option;
							break;
						}
					}
				} else {
					const option = resolveCompiledOption(item, config);
					map[option.computedHash] = option;
				}
			}

			return map;
		},
		[rawOptions, config.selection]
	) as SelectionMap<V>;

	return useMemo(
		() => {
			const selectable = {
				options: [],
				rawOptions: [],
				selection: [],
				rawSelection: []
			} as Selectable<V>;

			for (const option of rawOptions) {
				if (selectionMap.hasOwnProperty(option.computedHash)) {
					const selectedOption = resolveCompiledOption(option, config);
					selectedOption.selected = true;
					selectable.options.push(selectedOption);
					selectable.selection.push(selectedOption);
					selectable.rawSelection.push(selectedOption.value);
				} else
					selectable.options.push(option);

				selectable.rawOptions.push(option.value);
			}

			if (config.autoSet && !selectable.selection.length) {
				const selectedOption = resolveCompiledOption(selectable.options[0], config);
				selectedOption.selected = true;
				selectable.options[0] = selectedOption;
				selectable.selection.push(selectedOption);
				selectable.rawSelection.push(selectedOption.value);
			}

			return selectable;
		},
		[
			selectionMap,
			rawOptions,
			config.autoSet
		]
	);
}

function resolveCompiledOption<V>(
	option: Option<V> | CompiledOption<V>,
	config: SelectableConfig<V>
): CompiledOption<V> {
	if (isObject(option)) {
		const opt = option as OptionObject<V> | CompiledOption<V>;

		const compiled = {
			...opt,
			index: typeof (opt as any).index == "number" ?
				(opt as CompiledOption<V>).index :
				-1,
			selected: false,
			computedHash: "",
			isCompiledOption: true
		} as CompiledOption<V>;

		if ((opt as CompiledOption<V>).isCompiledOption) {
			compiled.computedHash = (opt as CompiledOption<V>).computedHash;
		} else {
			if (typeof opt.hash == "function")
				compiled.computedHash = opt.hash(compiled);
			else if (typeof config.hash == "function")
				compiled.computedHash = config.hash(compiled);
			else
				compiled.computedHash = compiled.label;
		}

		return compiled;
	}

	const compiled = {
		value: option as V,
		label: "",
		index: -1,
		selected: false,
		computedHash: "",
		isCompiledOption: true
	} as CompiledOption<V>;

	const label = typeof config.hash == "function" ?
		config.hash(compiled) :
		String(option);

	compiled.label = label;
	compiled.computedHash = label;

	return compiled;
}

const isOptionsObject = (candidate: any): boolean => {
	return isObject(candidate) && (
		candidate.hasOwnProperty("isCompiledOption") ||
		candidate.hasOwnProperty("value")
	);
};

export default useSelectable;
